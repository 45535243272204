import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './../../styles/NewsDetail.css';
import { setDefaultLanguage, getDefaultLanguage, getTranslation } from '../../translation';

interface Section {
  id: number;
  type: 'text' | 'image';
  content: string;
  position: number;
  filename?: string;
}

interface BlogPost {
  id: number;
  title: string;
  subtitle: string;
  featuredImage: string | null;
  status: 'draft' | 'published';
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  sections: Section[];
}

interface NewsDetailProps {
  baseUrl?: string; // API base URL
}

const NewsDetail: React.FC<NewsDetailProps> = ({ baseUrl = 'https://server.dotalive.org/api' }) => {
  // Changed from id to slug
  const { slug, language } = useParams();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPost();
  }, [slug]); // Changed dependency from id to slug

  const fetchPost = async () => {
    debugger
    if (!slug) return; 
    
    setLoading(true);
    setError(null);
    
    try {
      // Changed endpoint to use slug instead of ID
      const response = await fetch(`${baseUrl}/blog-posts/${slug}/${language}`);
      
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Article not found');
        }
        throw new Error('Failed to fetch article');
      }
      
      const data = await response.json();
      setPost(data);
    } catch (err) {
      console.error('Error fetching post:', err);
      setError(err instanceof Error ? err.message : 'Failed to load article');
      setPost(null);
    } finally {
      setLoading(false);
    }
  };

  // Format date for display
  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Render section content based on type
  const renderSection = (section: Section, index: number) => {
    if (section.type === 'text') {
      return (
        <div 
          key={`section-${index}`}
          className="article-text-section"
          dangerouslySetInnerHTML={{ __html: section.content }}
        />
      );
    } else if (section.type === 'image' && section.content) {
      return (
        <figure key={`section-${index}`} className="article-image-section">
          <img src={section.content} alt={section.filename || `Image ${index + 1}`} />
          {section.filename && (
            <figcaption>{section.filename}</figcaption>
          )}
        </figure>
      );
    }
    return null;
  };

  if (loading) {
    return <div className="article-loading">Loading article...</div>;
  }

  if (error) {
    return (
      <div className="article-error">
        <h2>Error</h2>
        <p>{error}</p>
        <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}`} className="back-to-news">Back to News</a>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="article-not-found">
        <h2>Article not found</h2>
        <p>The article you're looking for doesn't exist or has been removed.</p>
        <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}`} className="back-to-news">Back to News</a>
      </div>
    );
  }

  return (
    <div className="article-container">
        <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}`} className="back-to-news">        &larr; {getTranslation("news.back_to_home")}</a>

      
      <article className="article-content">
        <header className="article-header">
          <h1 className="article-title">{post.title}</h1>
          {post.subtitle && <h2 className="article-subtitle">{post.subtitle}</h2>}
          
          <div className="article-meta">
            {/* <time className="article-date"> */}
              {/* {formatDate(post.publishedAt)} */}
            {/* </time> */}
          </div>
        </header>
        
        {post.featuredImage && (
          <div className="article-featured-image">
            <img src={post.featuredImage} alt={post.title} />
          </div>
        )}
        
        <div className="article-body">
          {post.sections
            .sort((a, b) => a.position - b.position)
            .map((section, index) => renderSection(section, index))}
        </div>
      </article>
      
      <div className="article-footer">
      <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}`} className="back-to-news">        &larr; {getTranslation("news.back_to_home")}</a>

      </div>
    </div>
  );
};

export default NewsDetail;