import React, { useEffect, useState } from "react";
import { getDefaultLanguage, getTranslation } from "../translation";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps } from "../interfaces";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import '../App.css'
import ImageSrc from "../img/office.png"; // Adjust the path accordingly
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Language } from "@mui/icons-material";
import Overlay from "material-ui/internal/Overlay";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkIcon from "@mui/icons-material/Work";
interface Employee {
  name: string;
  surname: string;
  position: string;
  tel: string;
  email: string;
  img: string;
  big_img: string;
}

type Language = "mk" | "en" | "de";

export default function OurTeam({ isInHomepageView }: genericComponentProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any | null>(null); // Track the selected employee
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const { employeeId } = useParams();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(getDefaultLanguage());
  const [employees, setEmployees] = useState<any[]>([
    {
      email: "zlatkokolevski@gmail.com",
      name: "Zlatko",
      position: "Attorney",
      mk_position: "Адвокат",
      de_position: "Attorney",
      img: "https://storage.googleapis.com/nkit_eshop/Remove%20background%20project.webp",
      surname: "Kolevski",
      tel: "+389 7077 777",
      big_img: "https://storage.googleapis.com/nkit_eshop/zlatko_big2.webp",
      areas: ["Commercial Law", "Corporate Law", "Banking Law", "Media Law", "Administrative Law", "Real Estate Law", "Aviation Law", "Labor & Employment Law", "Litigation", "Property Law", "Bankruptcy Law"],

      memberSince: "1992",
      education: ["Ss. Cyril and Methodius University - Faculty of Law (Skopje, 1983-1989)", "Specialized in Administrative, Corporate and Civil Law"],
      languages: ["English", "Croatian", "Serbian"],
      experience: `Zlatko T. Kolevski is a highly respected attorney with over 32 years of experience in a broad spectrum of legal matters. He has served on government teams drafting new laws for companies and non-litigation procedures and has provided legal expertise for projects undertaken by the World Bank, UN-FAO, and OSCE.`,
    },
    {
      email: "sofija@gmail.com",
      name: "Sofija",
      position: "Lawyer",
      surname: "Naceva",
      img: "https://storage.googleapis.com/nkit_eshop/sofija_240.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_sofija.webp",
      tel: "+389 7077 888",
      areas: ["Commercial Law", "Family Law", "Inheritance Law", "Real Estate", "Labor Law", "Litigation"],
      memberSince: "2000",
      lawyerSince: "2005",
      education: ["Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (1995)", "Passed the Bar Exam (1998)"],
      languages: ["Serbian", "Croatian", "Bulgarian"],
    },
    {
      email: "vska@gmail.com",
      name: "Misho",
      position: "Lawyer",
      surname: "Smilevski",
      img: "https://storage.googleapis.com/nkit_eshop/misho_240.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_misho.webp",
      tel: "+389 7077 888",
      areas: ["Criminal Law", "Medical Law", "Compensation Claims", "Commercial Law", "Litigation"],
      memberSince: "2009",
      lawyerSince: "2008",
      education: ["Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (2007)", "Passed the Bar Exam (2009)"],
      languages: ["English", "Serbian", "Croatian"],
    },
    {
      email: "vska@gmail.com",
      name: "Neda",
      position: "Lawyer",
      img: "https://storage.googleapis.com/nkit_eshop/neda_240.webp",
      surname: "Karapanovska",
      big_img: "https://storage.googleapis.com/nkit_eshop/bigger_image_neda.webp",
      tel: "+389 7077 888",
      areas: ["Administrative Law", "Tax Law", "Inheritance Law", "Commercial Law", "Corporate Law"],
      memberSince: "2016",
      associateSince: "2010",
      education: ["Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje (2010)", "Passed the Bar Exam (2015)"],
      languages: ["English", "Serbian", "Croatian", "Spanish"],
    },
    {
      email: "tijanamarkovic@gmail.com",
      name: "Tijana",
      position: "Lawyer",
      img: "https://storage.googleapis.com/nkit_eshop/file.webp",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_tijana.webp",
      surname: "Markovikj",
      tel: "+389 7077 888",
      areas: ["International Law", "Immigration Law", "Education", "Commercial Law", "Corporate Law"],
      memberSince: "2013",
      associateSince: "2012",
      education: ["Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje", "Master's degree in Public International Law, Private International Law, International Relations, and EU Law (2011)", "Passed the Bar Exam (2012)"],
      languages: ["English", "Serbian", "Croatian"],
    },
    {
      email: "tomekolevski@gmail.com",
      img: "https://storage.googleapis.com/nkit_eshop/tome_240.webp",
      name: "Tome",
      position: "Lawyer",
      surname: "Kolevski",
      tel: "+389 7077 888",
      big_img: "https://storage.googleapis.com/nkit_eshop/big_tome.webp",
      areas: ["Financial Law", "Enforcement Law", "Real Estate", "Inheritance Law", "Administrative Law"],
      memberSince: "2021",
      associateSince: "2017",
      education: ["Ss. Cyril and Methodius University – Faculty of Law 'Justinian the First' – Skopje", "Master's degree in Financial Law and Finance (2018)", "Passed the Bar Exam (2019)"],
      languages: ["English", "Serbian", "Croatian", "Greek"],
    },

    {
      email: "mihaelakolevska@gmail.com",
      name: "Mihaela",
      position: "Paralegal",
      surname: "Kolevska",
      img: "https://storage.googleapis.com/nkit_eshop/mihaela_240.webp",
      tel: "+389 7077 111",
      big_img: "https://storage.googleapis.com/nkit_eshop/mihaela_big.webp",
      areas: ["Legal Research", "Client Communication", "Administrative Tasks and Organization", "Legal Document Preparation"],
      memberSince: "2022",
      education: ["Ss. Cyril and Methodius University - Faculty of Law 'Justinian the First' - Skopje", "LLM Candidate in Business Law (2024-2025)"],
      languages: ["English", "German", "Serbian", "Croatian"],
      experience: `Mihaela Kolevska is a dedicated and detail-oriented paralegal with a demonstrated passion for the legal field. She brings a strong academic background and a profound interest in business law to her role at Law Office Kolevski. Mihaela is skilled in conducting legal research, effectively communicating with clients, and managing administrative tasks with organization and efficiency. She is also proficient in preparing legal documents, ensuring accuracy and adherence to legal standards.`,
    },
  ]);

  const styles = {
    container: { padding: "0rem 10rem", background: "white", marginBottom: "1rem", overlay: "none" },
    header: { fontSize: "3.5rem" },
    subHeader: { fontSize: "1.5rem", lineHeight: "3.5rem" },
    hr: { background: "#013A4E", height: "2px", margin: "0.3rem 0rem", width: "5.5rem" },
    image: { width: "auto", height: "450px" },
    sectionTitle: { fontSize: "24px", lineHeight: "27px", fontWeight: "400", color: "#013A4E" },
    sectionContent: { fontSize: "18px", lineHeight: "27px", fontWeight: "400", color: "#013A4E" },
  };


// Replace the existing handleShowDetails function with this one
const handleShowDetails = (employee:any) => {
  // First force an immediate scroll to top
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  window.scrollTo(0, 0);
  
  // Then set state
  setSelectedEmployee(employee);
  setShowDetails(true);
  
  // Handle navigation after a minimal delay
  setTimeout(() => {
    const decodedPath = decodeURIComponent(location.pathname);
    
    if (decodedPath.includes("/our-team")) {
      navigate(`/our-team/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    } else if (decodedPath.includes("/нашиот-тим")) {
      navigate(`/нашиот-тим/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    } else {
      navigate(`/our-team/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    }
    
    // Force scroll again after navigation
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  }, 10);
  
  // Final fallback scroll after everything else
  setTimeout(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  }, 200);
};

// Also modify the useEffect that handles URL changes to ensure scrolling there too
useEffect(() => {
  if (employeeId) {
    let parts = employeeId?.split("-") as string[];
    
    // Force scroll to top immediately
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo(0, 0);
    
    const foundEmployee = employees.find((emp) => emp.name.toLowerCase() === parts[0] && emp.surname.toLowerCase() === parts[1]);

    if (foundEmployee) {
      setShowDetails(true);
      setSelectedEmployee(foundEmployee);
      
      // Force scroll again after a delay
      setTimeout(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.scrollTo(0, 0);
      }, 100);
    } else {
      setShowDetails(false);
    }
  } else {
    setShowDetails(false);
  }

  // Final scroll to ensure it worked
  setTimeout(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
  }, 200);
}, [employeeId, employees]);

// Add this useEffect to handle any navigation changes
useEffect(() => {
  // Force scroll to top whenever location changes
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
}, [location.pathname]);
  const handleGoBack = () => {
    setShowDetails(false);
    setSelectedEmployee(null);
    // Use navigate to go back to the team view
    navigate("/our-team");
  };

  interface TeamMemberProps {
    employee: any;
    row: number;
    isMobile: boolean;
  }

  const TeamMember = ({ employee, isMobile }:any) => {
    // Create unique class name for this employee
    const employeeClassName = `employee-class-${employee.name}`;
  
    // State to track hover
    const [isHovered, setIsHovered] = React.useState(false);
  
    // Create a unified handler that ensures scrolling works
    const handleEmployeeClick = (e:any) => {
      // First scroll to the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      // Small delay to ensure scroll starts before navigation
      setTimeout(() => {
        if (typeof handleShowDetails === 'function') {
          handleShowDetails(employee);
        }
      }, 50);
    };
  
    // Separate handler for the button to prevent double execution
    const handleButtonClick = (e:any) => {
      e.stopPropagation(); // Stop propagation to the parent div
      handleEmployeeClick(e);
    };
  
    return (
      <div 
        onClick={handleEmployeeClick}
        className="employee_card" 
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)} 
        style={{ 
          background: isHovered 
            ? "linear-gradient(to bottom, rgba(1, 58, 78, 0.1), rgba(1, 58, 78, 1))" 
            : "linear-gradient(to bottom, #D7D7D7, #D7D7D7)"
        }}
      >
        <div style={{
          background: 'white', 
          borderRadius: '20px', 
          marginTop: '-2rem'
        }}>
          <img 
            style={{ 
              marginTop: "-5.5rem", 
              maxWidth: "100%",
              marginBottom: '-0.4rem', 
              borderRadius: '20px'
            }} 
            src={employee.big_img}
            alt={`${employee.name} ${employee.surname}`}
          />
        </div>
        
        <span
  className="employee_name"
  style={{
    color: isHovered ? "white" : "rgb(1,58,78)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    fontSize: "min(5vw, 20px)", // or clamp() for more control
    maxWidth: "100%" // or any container constraint
  }}
>
  {getTranslation(`${employee.name}.name`)} {getTranslation(`${employee.name}.surname`)}
</span>

        
        <span 
          className="employee_position" 
          style={{
            color: isHovered ? "white" : "rgb(1,58,78)"
          }}
        >
{getTranslation(`${employee.name}.position`)}        </span>
        
        <div 
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px 5px",
            marginTop: '0.5rem',
            gap: "10px",
            width: "88%",
            // height: "49px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 15.3px rgba(0, 0, 0, 0.19)",
            borderRadius: "100px",
            border: "none",
            cursor: "pointer",
            flex: "none",
            order: 0,
            flexGrow: 0,
          }}
          onClick={handleButtonClick}
        >
          <span style={{
            height: "9px",
            fontFamily: "Poppins, sans-serif",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "13px",
            lineHeight: "9px",
            letterSpacing: "0.01em",
            color: "#013A4E",
            flex: "none",
            order: 0,
            flexGrow: 0,
          }}>
            {typeof getTranslation === 'function' 
              ? getTranslation("read_more") 
              : "See More"
            }
          </span>
        </div>
      </div>
    );
  };
  // const TeamMemberOld = ({ employee, isMobile }:any) => {
  //   // Create unique class name for this employee
  //   const employeeClassName = `employee-class-${employee.name}`;

  //   // State to track hover
  //   const [isHovered, setIsHovered] = React.useState(false);

  //   return (
  //     <div
  //       style={{
  //         width: "344px",
  //         height: "507px",
  //         position: "relative",
  //         flex: "none",
  //         order: 0,
  //         flexGrow: 0,
  //       }}
  //       onMouseEnter={() => setIsHovered(true)}
  //       onMouseLeave={() => setIsHovered(false)}
  //     >
  //       {/* Group 3 - Main container */}
  //       <div style={{
  //         position: "absolute",
  //         width: "344px",
  //         height: "429px",
  //         left: "0px",
  //         top: "0px",
  //       }}
  //       >
  //         {/* Subtract - Shadow background */}
  //         <div style={{
  //           position: "absolute",
  //           left: "0%",
  //           right: "0%",
  //           top: "0%",
  //           bottom: "-18.18%",
  //           background: "#D7D7D7",
  //           boxShadow: "0px 0px 13.6px rgba(102, 102, 102, 0.22)",
  //           borderRadius: "20px",
  //         }}></div>

  //         {/* Rectangle 1 - Main background with hover transition */}
  //         <div
  //           className={employeeClassName}
  //           style={{
  //             position: "absolute",
  //             left: "0%",
  //             right: "0%",
  //             top: "0%",
  //             bottom: "-18.18%",
  //             background: isHovered
  //               ? "linear-gradient(to bottom, rgba(1, 58, 78, 0.1), rgba(1, 58, 78, 1))"
  //               : "linear-gradient(to bottom, #D7D7D7, #D7D7D7)",
  //             borderRadius: "20px",
  //             transition: "background 0.3s ease-in-out",
  //           }}
  //         ></div>

  //         {/* Rectangle 4 - Green background for photo */}
  //         <div style={{
  //           position: "absolute",
  //           left: "7.85%",
  //           right: "8.14%",
  //           top: "6.06%",
  //           bottom: "23.31%",
  //           background: "white",
  //         }}></div>

  //         {/* Frame 2 - Container for name and title */}
  //         <div style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           padding: "0px",
  //           position: "absolute",
  //           height: "73px",
  //           left: "0%",
  //           right: "0%",
  //           top: "calc(50% - 73px/2 + 178px)",
  //         }}>
  //           {/* Frame 90 */}
  //           <div style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             padding: "0px",
  //             gap: "30px",
  //             width: "346px",
  //             height: "86px",
  //             flex: "none",
  //             order: 0,
  //             flexGrow: 0,
  //             margin: "-10px 0px",
  //           }}>
  //             {/* Frame 89 - Name and title container */}
  //             <div style={{
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "center",
  //               padding: "0px",
  //               gap: "3px",
  //               width: "346px",
  //               height: "64px",
  //               flex: "none",
  //               order: 0,
  //               flexGrow: 0,
  //             }}>
  //               {/* Name */}
  //               <div style={{
  //                 width: "327px",
  //                 height: "32px",
  //                 fontFamily: "Poppins, sans-serif",
  //                 fontStyle: "normal",
  //                 fontWeight: 700,
  //                 fontSize: "27px",
  //                 lineHeight: "40px",
  //                 textAlign: "center",
  //                 color:isHovered ?"white": "#013A4E",
  //                 flex: "none",
  //                 order: 0,
  //                 flexGrow: 0,
  //               }}>
  //                 {employee.name} {employee.surname}
  //               </div>

  //               {/* Position/Title */}
  //               <div style={{
  //                 width: "346px",
  //                 height: "29px",
  //                 fontFamily: "Poppins, sans-serif",
  //                 fontStyle: "normal",
  //                 fontWeight: 400,
  //                 fontSize: "20px",
  //                 lineHeight: "30px",
  //                 textAlign: "center",
  //                 color:isHovered ?"white": "#013A4E",
  //                 flex: "none",
  //                 order: 1,
  //                 flexGrow: 0,
  //               }}>
  //                 {typeof getTranslation === 'function'
  //                   ? getTranslation(`${employee.name}.position`)
  //                   : employee.position || "Attorney"}
  //               </div>
  //             </div>

  //             {/* Frame 92 - Button container */}
  //             <div style={{
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "flex-start",
  //               padding: "0px",
  //               gap: "8px",
  //               width: "288px",
  //               height: "49px",
  //               flex: "none",
  //               order: 1,
  //               flexGrow: 0,
  //             }}>
  //               {/* Frame 91 */}
  //               <div style={{
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "flex-start",
  //                 padding: "0px",
  //                 gap: "8px",
  //                 width: "288px",
  //                 height: "49px",
  //                 flex: "none",
  //                 order: 0,
  //                 flexGrow: 0,
  //               }}>
  //                 {/* Main BTN */}
  //                 <button style={{
  //                   display: "flex",
  //                   flexDirection: "row",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                   padding: "20px 30px",
  //                   gap: "10px",
  //                   width: "288px",
  //                   height: "49px",
  //                   background: "#FFFFFF",
  //                   boxShadow: "0px 4px 15.3px rgba(0, 0, 0, 0.19)",
  //                   borderRadius: "100px",
  //                   border: "none",
  //                   cursor: "pointer",
  //                   flex: "none",
  //                   order: 0,
  //                   flexGrow: 0,
  //                 }}
  //                 onClick={() => {
  //                   if (typeof handleShowDetails === 'function') {
  //                     handleShowDetails(employee);
  //                   }
  //                 }}
  //                 >
  //                   {/* See More text */}
  //                   <span style={{
  //                     height: "9px",
  //                     fontFamily: "Poppins, sans-serif",
  //                     fontStyle: "normal",
  //                     fontWeight: 600,
  //                     fontSize: "18px",
  //                     lineHeight: "9px",
  //                     letterSpacing: "0.01em",
  //                     color: "#013A4E",
  //                     flex: "none",
  //                     order: 0,
  //                     flexGrow: 0,
  //                   }}>
  //                     {typeof getTranslation === 'function' ? getTranslation("read_more") : "See More"}
  //                   </span>
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       {/* Profile image */}
  //       <div style={{
  //         position: "absolute",
  //         width: "292.67px",
  //         height: "351px",
  //         left: "25.66px",
  //         top: "-22px",
  //         backgroundImage: `url(${employee.big_img})`,
  //         backgroundPosition: "center",
  //         backgroundSize: "cover",
  //         backgroundRepeat: "no-repeat",
  //       }}></div>
  //     </div>
  //   );
  // };

  const ContactCard = ({ selectedEmployee, getTranslation }: any) => {
    return (
      <div
        className="employee_contact"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          padding: "15px",
          gap: "20px",
          width: "400px",
          height: "280px",
          background: "#FFFFFF",
          boxShadow: "0px 0px 13.6px rgba(102, 102, 102, 0.22)",
          borderRadius: "30px",
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "10px",
            gap: "10px",
            // width: '417px',
            // height: '300px',
            flex: "none",
            order: 0,
            alignSelf: "stretch",
            flexGrow: 0,
          }}
        >
          {/* Title and Line */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "0px",
              gap: "15px",
              width: "135px",
              // height: '63px',
              flex: "none",
              order: 0,
              flexGrow: 0,
            }}
          >
            <div
              style={{
                // width: '135px',
                // height: '48px',
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "32px",
                marginTop: "1rem",
                // lineHeight: '48px',
                letterSpacing: "0.03em",
                color: "#013A4E",
                flex: "none",
                order: 0,
                flexGrow: 0,
              }}
            >
              {getTranslation ? getTranslation("contact") : "Contact"}
            </div>
            <div
              style={{
                width: "64px",
                height: "0px",
                border: "2px solid #013A4E",
                flex: "none",
                order: 1,
                flexGrow: 0,
              }}
            ></div>
          </div>

          {/* Contact Information */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "0px",
              gap: "10px",
              // width: '397px',
              height: "187px",
              flex: "none",
              order: 1,
              alignSelf: "stretch",
              flexGrow: 0,
            }}
          >
            {/* Phone */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
                // width: '397px',
                height: "33px",
                flex: "none",
                order: 0,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
            >
              <LocalPhoneIcon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#013A4E",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                }}
              />
              <div
                style={{
                  width: "126px",
                  height: "33px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                  color: "#1E1E1E",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
              >
                {getTranslation && selectedEmployee ? getTranslation(`${selectedEmployee.name}.tel`) : "+389 77 777 777"}
              </div>
            </div>

            {/* Email */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
                // width: '397px',
                height: "33px",
                flex: "none",
                order: 1,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
            >
              <EmailIcon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#013A4E",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                }}
              />
              <div
                style={{
                  width: "150px",
                  height: "33px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                  color: "#1E1E1E",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
              >
                {getTranslation && selectedEmployee ? getTranslation(`${selectedEmployee.name}.email`) : "lorem@lorem.com"}
              </div>
            </div>

            {/* Profession */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
                // width: '397px',
                height: "33px",
                flex: "none",
                order: 2,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
            >
              <WorkIcon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#013A4E",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                }}
              />
              <div
                style={{
                  width: "75px",
                  height: "33px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                  color: "#1E1E1E",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
              >
                {getTranslation && selectedEmployee ? getTranslation(`${selectedEmployee.name}.position`) : "Attorney at Law"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
                // width: '397px',
                height: "33px",
                flex: "none",
                order: 2,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
            >
              <LocationOnOutlinedIcon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#013A4E",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                }}
              />
              <div
                style={{
                  width: "75px",
                  height: "33px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                  color: "#1E1E1E",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
              >
                {"Skopje"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showDetails ? (
        selectedEmployee && (
          <div id="details">
            <div id="our_team" className=" our_team-flex-row employee_bck" style={{ justifyContent: "space-between", alignItems: "flex-start"}}>
              <div className="our_team-flex-column" style={{ justifyContent: "start", paddingTop: "4rem" }}>
                <span className="our_team-header">
                  {getTranslation(`${selectedEmployee.name}.name`)} {getTranslation(`${selectedEmployee.name}.surname`)}
                </span>
                <span className="our_team-sub-header">{getTranslation(`${selectedEmployee.name}.position`)}</span>
                <hr className="our_team-hr" />
              </div>
              <div id="employee_img" className="our_team-employee-img">
                <img className="our_team-image" src={getTranslation(`${selectedEmployee.big_img}`)} alt={`${getTranslation(`${selectedEmployee.name}.name`)} ${getTranslation(`${selectedEmployee.name}.surname`)}`} />
              </div>
            </div>
            <div className="our_team-container data-employee our_team-flex-row" style={{ paddingTop: "1rem", paddingBottom: "10rem" }}>
              <div className="our_team-flex-column" style={{ width: "100%", gap: "0.5rem" }}>
                <span className="our_team-section-title" style={{ fontSize: "32px", lineHeight: "68px", fontWeight: "500" }}>
                  {getTranslation(`${selectedEmployee.name}.name`)} {getTranslation(`${selectedEmployee.name}.surname`)}
                </span>
                <span className="our_team-section-title">{getTranslation("Areas_of_Practice")}</span>
                <ul className="our_team-section-content">
                  {getTranslation(`${selectedEmployee.name}.areas`)
                    .split(",")
                    .map((area: string, index: number) => (
                      <li key={index}>{area.trim()}</li>
                    ))}
                </ul>
                <span className="our_team-section-title">{getTranslation("Education")}</span>
                <ul className="our_team-section-content">
                  {getTranslation(`${selectedEmployee.name}.education`)
                    .split(";")
                    .map((edu: string, index: number) => (
                      <li key={index}>{edu.trim()}</li>
                    ))}
                </ul>
                <span className="our_team-section-title">{getTranslation("Languages")}</span>
                <ul className="our_team-section-content">
                  {getTranslation(`${selectedEmployee.name}.languages`)
                    .split(",")
                    .map((lang: string, index: number) => (
                      <li key={index}>{lang.trim()}</li>
                    ))}
                </ul>
                <span className="our_team-section-title">{getTranslation("Experience")}</span>
                <p className="our_team-section-content">{getTranslation(`${selectedEmployee.name}.experience`)}</p>
              </div>
              <ContactCard selectedEmployee={selectedEmployee} getTranslation={getTranslation} />
            </div>
          </div>
        )
      ) : (
        <>
          <div id="our_team" style={{ paddingBottom: "10rem" }}>
            <span style={{ fontSize: "3.5rem", color: "#013A4E" }}>
              {getTranslation("our_team.header")}
              <hr
                style={{
                  background: "#013A4E",
                  height: "2px",
                  margin: "0.3rem 0rem 0rem 0rem",
                  width: "5.5rem",
                }}
              />
            </span>
            {/* desktopppppppppppppp */}
            <div
              className="small_screen_hide"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "30px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={false} employee={employees[0]} row={1} />
                      
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "30px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                    <TeamMember isMobile={false} employee={employees[1]} row={2} />
                      <TeamMember isMobile={false} employee={employees[2]} row={2} />
                      
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "30px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                    <TeamMember isMobile={false} employee={employees[3]} row={2} />
                      <TeamMember isMobile={false} employee={employees[4]} row={2} />
                      <TeamMember isMobile={false} employee={employees[5]} row={2} />

                      
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "30px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                    <TeamMember isMobile={false} employee={employees[6]} row={2} />
                      
                    </div>
                  </>
                );
              })}

              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[3] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[3]} row={2} />
                  </>
                ) : (
                  <></>
                )}

              </div> */}
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop:'3rem',
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "30px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[3]} row={2} />

                    <TeamMember isMobile={false} employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[5] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[5]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[6] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[6]} row={2} />
                  </>
                ) : (
                  <></>
                )}
                {employees[7] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[7]} row={2} />
                  </>
                ) : (
                  <></>
                )}
              </div> */}
            </div>
            {/* tablet */}
            <div
              className="mobile_screen_show"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={false} employee={employees[0]} row={1} />
                    </div>
                  </>
                );
              })}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {/* <div>SHOW INFO</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[3] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[3]} row={2} />
                  </>
                ) : (
                  <></>
                )}
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[3] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[5]} row={2} />
                  </>
                ) : (
                  <></>
                )}
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false} employee={employees[6]} row={2} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* mobile */}
            <div
              className="mobile_screen_show_smallest"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                padding: "0rem 0rem",
              }}
            >
              {employees.map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={true} employee={Employee} row={1} />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
