import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './../../styles/BlogAdmin.css';
import { setDefaultLanguage, getDefaultLanguage, getTranslation } from '../../translation';
type Language = "mk" | "en" | "de";

interface Section {
  id: number;
  type: 'text' | 'image';
  content: string;
  position: number;
  filename?: string;
}

interface BlogPost {
  id: number;
  title: string;
  subtitle: string;
  slug: string;
  featured_image_url: string | null;
  status: 'draft' | 'published';
  published_at: string | null;
  created_at: string;
  updated_at: string;
  sections: Section[];
}

interface PaginationInfo {
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

interface BlogAdminProps {
  baseUrl?: string; // API base URL
}

const BlogAdmin: React.FC<BlogAdminProps> = ({ baseUrl = 'https://server.dotalive.org/api' }) => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>({
    page: 1,
    pageSize: 10,
    totalPages: 1,
    totalItems: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<'all' | 'published' | 'draft'>('all');
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [postToDelete, setPostToDelete] = useState<BlogPost | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [deleteSuccess, setDeleteSuccess] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const correctPassword = "kolevskiblog";
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(getDefaultLanguage());


  
  const navigate = useNavigate();

  // Check for saved authentication in sessionStorage
  useEffect(() => {
    const authenticated = sessionStorage.getItem("blogAuthenticated");
    if (authenticated === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  // Handle password submission
  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (password === correctPassword) {
      setIsAuthenticated(true);
      setPasswordError("");
      // Store authentication in session storage
      sessionStorage.setItem("blogAuthenticated", "true");
    } else {
      setPasswordError("Incorrect password. Please try again.");
    }
  };

  // Fetch posts when page or filter changes
  useEffect(() => {
    if (isAuthenticated) {
      fetchPosts(pagination.page);
    }
  }, [pagination.page, filter, isAuthenticated]);

  // Function to fetch posts from the API with optional status filter
  const fetchPosts = async (page: number) => {
    setLoading(true);
    setError(null);
    
    try {
      let url = `${baseUrl}/admin-blog-posts?page=${page}&pageSize=${pagination.pageSize}`;
      
      // Add status filter if not 'all'
      if (filter !== 'all') {
        url += `&status=${filter}`;
      }
      
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      
      const data = await response.json();
      
      setPosts(data.posts);
      setPagination({
        page: data.pagination.page,
        pageSize: data.pagination.pageSize,
        totalPages: data.pagination.totalPages,
        totalItems: data.pagination.totalItems
      });
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load blog posts. Please try again later.');
      setPosts([]);
    } finally {
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.totalPages) return;
    setPagination({ ...pagination, page: newPage });
  };

  // Handle filter change
  const handleFilterChange = (newFilter: 'all' | 'published' | 'draft') => {
    setFilter(newFilter);
    setPagination({ ...pagination, page: 1 }); // Reset to first page when filter changes
  };

  // Format date for display
  const formatDate = (dateString: string | null): string => {
    if (!dateString) return 'Not published';
    
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Open delete confirmation modal
  const openDeleteModal = (post: BlogPost) => {
    setPostToDelete(post);
    setDeleteModalOpen(true);
    setDeleteError(null);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setPostToDelete(null);
    setDeleteError(null);
  };

  // Handle delete post
  const handleDeletePost = async () => {
    if (!postToDelete) return;
    
    try {
      const response = await fetch(`${baseUrl}/delete-blog-post/${postToDelete.id}`, {
  method: 'DELETE',
});
      
      if (!response.ok) {
        throw new Error('Failed to delete post');
      }
      
      // Remove post from state
      setPosts(posts.filter(post => post.id !== postToDelete.id));
      setDeleteSuccess(`Post "${postToDelete.title}" has been deleted.`);
      setTimeout(() => setDeleteSuccess(null), 3000); // Clear success message after 3 seconds
      closeDeleteModal();
    } catch (err) {
      console.error('Error deleting post:', err);
      setDeleteError('Failed to delete post. Please try again.');
    }
  };

  // Navigate to edit page
  const navigateToEdit = (slug: string) => {
    navigate(`/news/${slug}/edit`);
  };

  // Render pagination controls
  const renderPagination = () => {
    const { page, totalPages } = pagination;
    
    if (totalPages <= 1) return null;
    
    // Calculate which page numbers to show
    const pageNumbers = [];
    const maxPagesToShow = 5;
    
    let startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;
    
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    return (
      <div className="admin-pagination">
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(1)} 
          disabled={page === 1}
        >
          &laquo;
        </button>
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(page - 1)} 
          disabled={page === 1}
        >
          &lsaquo;
        </button>
        
        {pageNumbers.map(num => (
          <button 
            key={num}
            className={`pagination-button ${num === page ? 'active' : ''}`}
            onClick={() => handlePageChange(num)}
          >
            {num}
          </button>
        ))}
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(page + 1)} 
          disabled={page === totalPages}
        >
          &rsaquo;
        </button>
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(totalPages)} 
          disabled={page === totalPages}
        >
          &raquo;
        </button>
      </div>
    );
  };

  // Render status badge
  const renderStatusBadge = (status: string) => {
    return (
      <span className={`status-badge ${status.toLowerCase()}`}>
        {status === 'published' ? 'Published' : 'Draft'}
      </span>
    );
  };

  // Render delete confirmation modal
  const renderDeleteModal = () => {
    if (!deleteModalOpen || !postToDelete) return null;
    
    return (
      <div className="delete-modal-backdrop">
        <div className="delete-modal">
          <h3>Confirm Delete</h3>
          <p>Are you sure you want to delete the post "<strong>{postToDelete.title}</strong>"?</p>
          <p className="delete-warning">This action cannot be undone.</p>
          
          {deleteError && <div className="delete-error">{deleteError}</div>}
          
          <div className="delete-modal-buttons">
            <button 
              className="cancel-button"
              onClick={closeDeleteModal}
            >
              Cancel
            </button>
            <button 
              className="delete-button"
              onClick={handleDeletePost}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render password form
  const renderPasswordForm = () => {
    return (
      <div className="password-container">
        <h2 className="password-title">Protected Content</h2>
        <p className="password-instruction">Please enter the password to access the admin area.</p>

        <form onSubmit={handlePasswordSubmit} className="password-form">
          <input 
            type="password" 
            placeholder="Enter password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            className="password-input"
          />

          <button type="submit" className="password-submit">
            Submit
          </button>
        </form>

        {passwordError && <p className="password-error">{passwordError}</p>}
      </div>
    );
  };

  // If not authenticated, show password form
  if (!isAuthenticated) {
    return (
      <div className="blog-admin-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {renderPasswordForm()}
      </div>
    );
  }

  return (
    <div className="blog-admin-container">
      <div className="admin-header">
        <h1>Blog Post Management</h1>
        <div className="admin-actions">
          <div className="filter-controls">
            <button 
              className={`filter-button ${filter === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterChange('all')}
            >
              All
            </button>
            <button 
              className={`filter-button ${filter === 'published' ? 'active' : ''}`}
              onClick={() => handleFilterChange('published')}
            >
              Published
            </button>
            <button 
              className={`filter-button ${filter === 'draft' ? 'active' : ''}`}
              onClick={() => handleFilterChange('draft')}
            >
              Drafts
            </button>
          </div>
          <Link to="/create-news" target='_blank' className="create-post-button">
            Create New Post
          </Link>
        </div>
      </div>
      
      {deleteSuccess && <div className="success-message">{deleteSuccess}</div>}
      
      {loading && <div className="admin-loading">Loading posts...</div>}
      
      {error && <div className="admin-error">{error}</div>}
      
      {!loading && !error && posts.length === 0 && (
        <div className="admin-empty">No posts found. {filter !== 'all' ? `Try changing the filter.` : ''}</div>
      )}
      
      {!loading && !error && posts.length > 0 && (
        <div className="posts-table-wrapper">
          <table className="posts-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Created</th>
                <th>Published</th>
                <th>Last Updated</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map(post => {
                if (filter != "all" && post.status != filter) {
                    return
                }
                return <tr key={post.id}>
                <td className="post-title-cell">
                  <div className="post-title">
                    {post.title}
                    <span className="post-subtitle">{post.subtitle}</span>
                  </div>
                </td>
                <td>{renderStatusBadge(post.status)}</td>
                <td>{formatDate(post.created_at)}</td>
                <td>{formatDate(post.published_at)}</td>
                <td>{formatDate(post.updated_at)}</td>
                <td className="actions-cell">
                  <div className="post-actions">
                    <Link to={`/news/${post.slug}/${selectedLanguage}`} className="view-button" target="_blank">
                      View
                    </Link>
                    <button 
                      className="edit-button"
                      onClick={() => navigateToEdit(post.slug)}
                    >
                      Edit
                    </button>
                    <button 
                      className="delete-button"
                      onClick={() => openDeleteModal(post)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
              })}
            </tbody>
          </table>
        </div>
      )}
      
      {renderPagination()}
      
      {renderDeleteModal()}
    </div>
  );
};

export default BlogAdmin;