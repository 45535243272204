import React, { useState, ChangeEvent, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./../../styles/BlogEditor.css";
import ImageUploader from "./ImageUploader";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";

// Define section types
type SectionType = "text" | "image";

interface SectionContent {
  content: string | File;
  lang: string;
}

interface Section {
  id: string;
  type: SectionType;
  contents: { [key: string]: string | File }; // Map language codes to content
  filename?: string;
}

interface LanguageOption {
  value: string;
  label: string;
  flag: string;
}

// Define language options
const languages: LanguageOption[] = [
  { value: "en", label: "English", flag: "🇬🇧" },
  { value: "mk", label: "Macedonian", flag: "🇲🇰" },
  { value: "es", label: "Spanish", flag: "🇪🇸" },
  { value: "ru", label: "Russian", flag: "🇷🇺" },
];

interface BlogPost {
  id?: number;
  title: string;
  subtitle: string;
  slug: string;
  featuredImage: File | null;
  featuredImageUrl?: string;
  sections: Section[];
  schema: string;
  status?: "draft" | "published";
  publishedAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

// API response interfaces
interface UploadResponse {
  url: string;
  filename: string;
}

interface SavePostResponse {
  id: number;
  status: string;
  message: string;
}

const BlogEditor: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location.pathname.includes("/edit");

  // Create a unique ID for sections
  const generateId = () => `section-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

  // Password protection states
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const correctPassword = "kolevskiblog";
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(["en"]);
  const [currentEditLanguage, setCurrentEditLanguage] = useState<string>("en");

  // Get full language info for selected values
  const getSelectedLanguageInfo = () => {
    return selectedLanguages.map((lang) => languages.find((option) => option.value === lang));
  };

  // MultiLanguageDropdown component with console logging for language changes
  const MultiLanguageDropdown: React.FC = () => {
    // const [selectedLanguages, setSelectedLanguages] = useState<string[]>(['en']);

    const handleChange = (event: SelectChangeEvent<typeof selectedLanguages>) => {
      const {
        target: { value },
      } = event;
    
      // Convert to array if it's a string
      const newSelectedLanguages = typeof value === "string" ? value.split(",") : value;
    
      // Find added languages (present in new selection but not in the current one)
      const addedLanguages = newSelectedLanguages.filter((lang) => !selectedLanguages.includes(lang));
    
      // Find removed languages (present in current selection but not in the new one)
      const removedLanguages = selectedLanguages.filter((lang) => !newSelectedLanguages.includes(lang));
    
      // Log added languages
      if (addedLanguages.length > 0) {
        console.log(
          "Added languages:",
          addedLanguages.map((langValue) => {
            const lang = languages.find((l) => l.value === langValue);
            return lang ? `${lang.flag} ${lang.label}` : langValue;
          })
        );
      }
    
      // Log removed languages
      if (removedLanguages.length > 0) {
        console.log(
          "Removed languages:",
          removedLanguages.map((langValue) => {
            const lang = languages.find((l) => l.value === langValue);
            return lang ? `${lang.flag} ${lang.label}` : langValue;
          })
        );
    
        // Remove the content for removed languages from all sections
        setBlogPost(prevBlogPost => {
          const updatedSections = prevBlogPost.sections.map(section => {
            if (section.type === "text") {
              // Create a new contents object without the removed languages
              const updatedContents = { ...section.contents };
              
              // Delete all removed languages from contents
              removedLanguages.forEach(lang => {
                delete updatedContents[lang];
              });
              
              return {
                ...section,
                contents: updatedContents
              };
            }
            return section;
          });
    
          return {
            ...prevBlogPost,
            sections: updatedSections
          };
        });
      }
    
      // Update the state
      setSelectedLanguages(newSelectedLanguages);
    
      // If current edit language was removed, switch to another available language
      if (removedLanguages.includes(currentEditLanguage)) {
        // Prioritize English if available in the new selection
        if (newSelectedLanguages.includes("en")) {
          setCurrentEditLanguage("en");
        } 
        // Otherwise use the first language in the new selection
        else if (newSelectedLanguages.length > 0) {
          setCurrentEditLanguage(newSelectedLanguages[0]);
        }
      }
    
      // Initialize content for newly added languages
      if (addedLanguages.length > 0) {
        setBlogPost(prevBlogPost => {
          const updatedSections = prevBlogPost.sections.map(section => {
            if (section.type === "text") {
              const updatedContents = { ...section.contents };
              
              // Add empty content for new languages
              addedLanguages.forEach(lang => {
                updatedContents[lang] = "";
              });
              
              return {
                ...section,
                contents: updatedContents
              };
            }
            return section;
          });
    
          return {
            ...prevBlogPost,
            sections: updatedSections
          };
        });
      }
    
      // Log the updated list of languages
      console.log(
        "Current selected languages:",
        newSelectedLanguages.map((langValue) => {
          const lang = languages.find((l) => l.value === langValue);
          return lang ? `${lang.flag} ${lang.label}` : langValue;
        })
      );
    };

    // Get full language info for selected values
    const getSelectedLanguageInfo = () => {
      return selectedLanguages.map((lang) => languages.find((option) => option.value === lang));
    };

    return (
      <div style={{ width: "100%" }}>
        <FormControl fullWidth>
          <InputLabel id="multiple-language-label">Languages</InputLabel>
          <Select
            labelId="multiple-language-label"
            id="multiple-language-select"
            multiple
            value={selectedLanguages}
            onChange={handleChange}
            input={<OutlinedInput label="Blog Languages" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const lang = languages.find((l) => l.value === value);
                  return (
                    <Chip
                      key={value}
                      label={
                        <span>
                          <span style={{ marginRight: "4px" }}>{lang?.flag}</span>
                          {lang?.label}
                        </span>
                      }
                    />
                  );
                })}
              </Box>
            )}
          >
            {languages.map((language) => (
              <MenuItem key={language.value} value={language.value}>
                <span style={{ marginRight: "8px" }}>{language.flag}</span>
                {language.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  // Blog post state
  const [blogPost, setBlogPost] = useState<BlogPost>({
    title: "",
    subtitle: "",
    slug: "",
    featuredImage: null,
    sections: [], // Start with empty sections array
    schema: "",
  });

  // Original post data for comparison in edit mode
  const [originalPost, setOriginalPost] = useState<BlogPost | null>(null);
  const [fetchingPost, setFetchingPost] = useState<boolean>(false);

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [createdPostId, setCreatedPostId] = useState<number | null>(null);

  // Set active section when sections change
  useEffect(() => {
    if (blogPost.sections.length === 0 && !fetchingPost && !isEditMode) {
      // Only add a default section if we're creating a new post (not editing)
      // and there are no sections yet
      setBlogPost(prev => ({
        ...prev,
        sections: [
          {
            id: generateId(),
            type: "text",
            contents: { en: "" },
          },
        ]
      }));
    }
    
    // Set active section when sections change
    if (blogPost.sections.length > 0 && !activeSection) {
      setActiveSection(blogPost.sections[0].id);
    }
  }, [blogPost.sections, activeSection, fetchingPost, isEditMode]);
  
  // Generate slug from title
  const generateSlugFromTitle = (title: string): string => {
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, "") // Remove special characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-") // Replace multiple hyphens with a single one
      .trim();
  };

  // Check for saved authentication in sessionStorage
  useEffect(() => {
    const authenticated = sessionStorage.getItem("blogAuthenticated");
    if (authenticated === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  // Fetch blog post when in edit mode
  useEffect(() => {
    if (isAuthenticated && isEditMode && slug) {
      fetchBlogPost(slug);
    }
  }, [isEditMode, slug, isAuthenticated]);
  useEffect(() => {
    if (!isEditMode && blogPost.sections.length > 0) {
      // For new posts, make sure all selected languages have entries in each text section
      setBlogPost(prev => {
        const updatedSections = prev.sections.map(section => {
          if (section.type === "text") {
            const updatedContents = { ...section.contents };
            
            // Make sure every selected language has an entry
            selectedLanguages.forEach(lang => {
              if (!updatedContents[lang]) {
                updatedContents[lang] = '';
              }
            });
            
            return {
              ...section,
              contents: updatedContents
            };
          }
          return section;
        });
        
        return {
          ...prev,
          sections: updatedSections
        };
      });
    }
  }, [selectedLanguages, isEditMode]);
  
  // Function to fetch blog post by slug for editing
  // Update the fetchBlogPost function in your BlogEditor component

  const fetchBlogPost = async (slug: string) => {
    setFetchingPost(true);
    setApiError("");
  
    try {
      const response = await fetch(`https://server.dotalive.org/api/blog-posts/by-slug/${slug}`);
  
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("Article not found");
        }
        throw new Error("Failed to fetch article");
      }
  
      const post = await response.json();
      console.log("Fetched post data:", post);
  
      // Detect available languages in sections
      const availableLanguages = new Set<string>();
  
      // First pass: collect all languages from all text sections
      if (post.sections && post.sections.length > 0) {
        post.sections.forEach((section: any) => {
          if (section.type === "text" && section.contents) {
            // Get all language keys from the contents object
            Object.keys(section.contents).forEach(lang => {
              if (lang !== 'image') { // Skip the image key
                availableLanguages.add(lang);
              }
            });
          }
        });
      }
  
      // If no languages were found, default to English
      if (availableLanguages.size === 0) {
        availableLanguages.add("en");
      }
  
      // Update the selected languages
      const languagesArray = Array.from(availableLanguages);
      setSelectedLanguages(languagesArray);
      
      // Set current edit language - prioritize English if it exists, otherwise use first available
      if (availableLanguages.has("en")) {
        setCurrentEditLanguage("en");
      } else if (languagesArray.length > 0) {
        setCurrentEditLanguage(languagesArray[0]);
      }
  
      // Convert the retrieved post to the format our editor expects
      const convertedSections = post.sections.map((section: any) => {
        if (section.type === "text") {
          // Make sure we have a valid contents object
          const contents = section.contents || {};
          
          // If contents is empty and we have legacy content, convert it
          if (Object.keys(contents).length === 0 && section.content) {
            const lang = section.language || 'en';
            contents[lang] = section.content;
          }
          
          // Ensure all selected languages have at least an empty string
          languagesArray.forEach(lang => {
            if (!contents[lang]) {
              contents[lang] = '';
            }
          });
          
          return {
            id: generateId(),
            type: section.type,
            contents: contents,
          };
        } else if (section.type === "image") {
          return {
            id: generateId(),
            type: section.type,
            contents: { 
              "image": section.content || (section.contents && section.contents.image) || '' 
            },
            filename: section.filename || undefined,
          };
        }
        return null; // Fallback for unknown section types
      }).filter(Boolean); // Remove null entries
      
      // ADD THIS CODE HERE - AFTER PROCESSING SECTIONS BUT BEFORE CREATING ADAPTED POST
      // Ensure we have at least one section if none were found
      if (convertedSections.length === 0) {
        convertedSections.push({
          id: generateId(),
          type: "text",
          contents: { [currentEditLanguage]: "" },
        });
      }
      
  
      
      // Create an adapted post object
      const adaptedPost: BlogPost = {
        id: post.id,
        title: post.title,
        subtitle: post.subtitle || '',
        slug: post.slug,
        featuredImage: null, // We can't convert URL to File directly
        featuredImageUrl: post.featuredImage,
        sections: convertedSections,
        schema: post.schema || "",
        status: post.status,
        publishedAt: post.publishedAt,
        createdAt: post.createdAt,
        updatedAt: post.updatedAt,
      };
  
      console.log("Adapted post data:", adaptedPost);
      console.log("Available languages:", languagesArray);
      console.log("Current edit language:", availableLanguages.has("en") ? "en" : languagesArray[0]);
  
      // Additional check: ensure every text section has content for every language
      adaptedPost.sections = adaptedPost.sections.map(section => {
        if (section.type === "text") {
          const updatedContents = { ...section.contents };
          
          // Make sure every language has an entry
          languagesArray.forEach(lang => {
            if (!updatedContents[lang]) {
              updatedContents[lang] = '';
            }
          });
          
          return {
            ...section,
            contents: updatedContents
          };
        }
        return section;
      });
  
      setBlogPost(adaptedPost);
      setOriginalPost(adaptedPost);
  
      // Set the first section as active
      if (convertedSections.length > 0) {
        setActiveSection(convertedSections[0].id);
      }
    } catch (err) {
      console.error("Error fetching post:", err);
      setApiError("Failed to load blog post. Please try again later.");
    } finally {
      setFetchingPost(false);
    }
  };
  // Handle password submission
  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (password === correctPassword) {
      setIsAuthenticated(true);
      setPasswordError("");
      // Store authentication in session storage
      sessionStorage.setItem("blogAuthenticated", "true");
    } else {
      setPasswordError("Incorrect password. Please try again.");
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    const updatedBlogPost = {
      ...blogPost,
      [name]: value,
    };

    // Auto-generate slug when title changes and slug is empty or not in edit mode
    if (name === "title" && (!blogPost.slug || !isEditMode)) {
      updatedBlogPost.slug = generateSlugFromTitle(value);
    }

    setBlogPost(updatedBlogPost);

    // Clear error when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Handle section content changes
  const handleSectionChange = (sectionId: string, content: string | File) => {
    console.log("Updating section:", sectionId, "language:", currentEditLanguage, "content:", content);
    
    // Check if content is already the same as what's in state to prevent infinite loops
    const currentSection = blogPost.sections.find(section => section.id === sectionId);
    
    if (currentSection) {
      if (currentSection.type === "text") {
        const currentContent = currentSection.contents?.[currentEditLanguage] || "";
        // Only update if content is different
        if (currentContent === content) {
          return; // Exit early if content hasn't changed
        }
      } else if (currentSection.type === "image" && currentSection.contents?.["image"] === content) {
        return; // Exit early if image content hasn't changed
      }
    }
  
    // Proceed with the update
    setBlogPost(prevBlogPost => {
      const updatedSections = prevBlogPost.sections.map(section => {
        if (section.id === sectionId) {
          if (section.type === "image") {
            return { 
              ...section, 
              contents: { 
                ...section.contents,
                "image": content 
              } 
            };
          } else {
            // For text sections, update only the current language content
            const updatedContents = { ...section.contents };
            updatedContents[currentEditLanguage] = content;
            
            console.log("Updated contents:", updatedContents);
            
            return { 
              ...section, 
              contents: updatedContents
            };
          }
        }
        return section;
      });
  
      return {
        ...prevBlogPost,
        sections: updatedSections,
      };
    });
  
    // Clear body error if any
    if (errors.body) {
      setErrors(prevErrors => ({
        ...prevErrors,
        body: "",
      }));
    }
  };

  // Add a new section
  const addSection = (type: SectionType) => {
    const newContents: { [key: string]: string | File } = {};
    
    if (type === "text") {
      // Initialize content for all selected languages
      selectedLanguages.forEach(lang => {
        newContents[lang] = "";
      });
    }
    
    const newSection = {
      id: generateId(),
      type,
      contents: type === "text" ? newContents : {},
    };
  
    console.log("Adding new section with contents:", newContents);
  
    setBlogPost(prev => ({
      ...prev,
      sections: [...prev.sections, newSection as any],
    }));
  };

  // Remove a section
  const removeSection = (sectionId: string) => {
    // Don't allow removing the last section
    if (blogPost.sections.length <= 1) {
      return;
    }

    const updatedSections = blogPost.sections.filter((section) => section.id !== sectionId);
    setBlogPost({
      ...blogPost,
      sections: updatedSections,
    });

    // If we're removing the active section, set the first one as active
    if (sectionId === activeSection && updatedSections.length > 0) {
      setActiveSection(updatedSections[0].id);
    }
  };

  // Move section up
  const moveSectionUp = (index: number) => {
    if (index <= 0) return;

    const updatedSections = [...blogPost.sections];
    const temp = updatedSections[index];
    updatedSections[index] = updatedSections[index - 1];
    updatedSections[index - 1] = temp;

    setBlogPost({
      ...blogPost,
      sections: updatedSections,
    });
  };

  // Move section down
  const moveSectionDown = (index: number) => {
    if (index >= blogPost.sections.length - 1) return;

    const updatedSections = [...blogPost.sections];
    const temp = updatedSections[index];
    updatedSections[index] = updatedSections[index + 1];
    updatedSections[index + 1] = temp;

    setBlogPost({
      ...blogPost,
      sections: updatedSections,
    });
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!blogPost.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!blogPost.slug.trim()) {
      newErrors.slug = "URL slug is required";
    } else if (!/^[a-z0-9-]+$/.test(blogPost.slug)) {
      newErrors.slug = "Slug can only contain lowercase letters, numbers, and hyphens";
    }

    // Check if there's at least one text section with content
    const hasContent = blogPost.sections.some((section) => {
      if (section.type === "text") {
        return Object.values(section.contents).some((content) => typeof content === "string" && content.trim() !== "");
      }
      return false;
    });

    if (!hasContent) {
      newErrors.body = "At least one text section with content is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Upload a single image to Google Cloud Storage
  const uploadImage = async (file: File): Promise<UploadResponse> => {
    // Create form data for the image
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch("https://server.dotalive.org/api/upload-image", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to upload image");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // Process all sections, uploading images and converting to save format
  const processSections = async (sections: Section[]): Promise<any[]> => {
    const processedSections = [];

    for (const section of sections) {
      if (section.type === "text") {
        // For text sections, include all language contents
        processedSections.push({
          type: "text",
          contents: section.contents,
        });
      } else if (section.type === "image" && section.contents["image"]) {
        try {
          const imageContent = section.contents["image"];
          // If the content is already a URL (existing image in edit mode)
          if (typeof imageContent === "string" && (imageContent.startsWith("http") || imageContent.startsWith("/"))) {
            processedSections.push({
              type: "image",
              content: imageContent,
              filename: section.filename || null,
            });
          } else {
            // For new image sections, upload the image and store the URL
            const uploadResult = await uploadImage(imageContent as File);
            processedSections.push({
              type: "image",
              content: uploadResult.url,
              filename: uploadResult.filename,
            });
          }
        } catch (error) {
          console.error("Error processing image section:", error);
          throw error;
        }
      }
    }

    return processedSections;
  };

  // Save or publish the blog post (or update if in edit mode)
  const savePost = async (isDraftMode: boolean) => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);
    setApiError("");
    setSuccessMessage("");
    setIsDraft(isDraftMode);

    try {
      // Process featured image if it exists
      let featuredImageUrl = blogPost.featuredImageUrl || null;
      let featuredImageFilename = null;

      if (blogPost.featuredImage) {
        const uploadResult = await uploadImage(blogPost.featuredImage);
        featuredImageUrl = uploadResult.url;
        featuredImageFilename = uploadResult.filename;
      }

      // Process sections (uploading images as needed)
      const processedSections = await processSections(blogPost.sections);

      // Prepare the post data for saving to the database
      const postData = {
        title: blogPost.title,
        subtitle: blogPost.subtitle,
        slug: blogPost.slug,
        featuredImage: featuredImageUrl,
        featuredImageFilename: featuredImageFilename,
        sections: processedSections,
        status: isDraftMode ? "draft" : "published",
        publishedAt: isDraftMode ? null : new Date().toISOString(),
        createdAt: isEditMode ? blogPost.createdAt : new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      let response;
      let url;
      let method;

      // Different API endpoints for create vs update
      if (isEditMode && originalPost?.id) {
        url = `https://server.dotalive.org/api/update-blog-post/${originalPost.id}`;
        method = "PUT";
      } else {
        url = "https://server.dotalive.org/api/create-blog";
        method = "POST";
      }

      // Send the post data to the backend
      response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to ${isEditMode ? "update" : "save"} blog post`);
      }

      const responseData: SavePostResponse = await response.json();
      setCreatedPostId(responseData.id);

      // Show success message
      if (isEditMode) {
        setSuccessMessage(isDraftMode ? "Post updated and saved as draft!" : "Post updated and published successfully!");
      } else {
        setSuccessMessage(isDraftMode ? "Draft saved successfully!" : "Blog post published successfully!");
      }

      // Wait a moment before redirecting
      setTimeout(() => {
        navigate("/news/admin");
      }, 2000);
    } catch (error) {
      console.error(`Error ${isEditMode ? "updating" : "saving"} blog post:`, error);
      setApiError(error instanceof Error ? error.message : "An unknown error occurred");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    savePost(false); // false = publish mode
  };

  const saveDraft = () => {
    savePost(true); // true = draft mode
  };

  const generateSchemaMarkup = () => {
    const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      headline: blogPost.title,
      description: blogPost.subtitle,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `https://example.com/news/${blogPost.slug}`,
      },
      datePublished: blogPost.publishedAt || new Date().toISOString(),
      dateModified: new Date().toISOString(),
      author: {
        "@type": "Person",
        name: "Author Name",
      },
    };

    setBlogPost({
      ...blogPost,
      schema: JSON.stringify(schemaMarkup, null, 2),
    });
  };

  // ReactQuill modules configuration
  const quillModules = {
    toolbar: [["bold", "italic", "underline", "strike"], [{ header: 1 }, { header: 2 }], [{ list: "ordered" }, { list: "bullet" }], ["blockquote", "code-block"], ["link"], ["clean"]],
  };

  // Render password form
  const renderPasswordForm = () => {
    return (
      <div className="password-container">
        <h2 className="password-title">Protected Content</h2>
        <p className="password-instruction">Please enter the password to view the editor.</p>

        <form onSubmit={handlePasswordSubmit} className="password-form">
          <input type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} className="password-input" />

          <button type="submit" className="password-submit">
            Submit
          </button>
        </form>

        {passwordError && <p className="password-error">{passwordError}</p>}
      </div>
    );
  };

  // Render each section based on its type
  const renderSection = (section: Section, index: number) => {
    return (
      <div key={section.id} className={`content-section ${section.id === activeSection ? "active" : ""}`} onClick={() => setActiveSection(section.id)}>
        <div className="section-header">
          <div className="section-type">
            {section.type === "text" ? "Text Section" : "Image Section"} #{index + 1}
          </div>

          <div className="section-controls">
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              {selectedLanguages.map((langValue) => {
                if (langValue != currentEditLanguage) {
                  return;
                }
                // Find the full language object that matches the selected value
                const langInfo = languages.find((lang) => lang.value === langValue);

                // Only render if we found matching language info
                return (
                  langInfo && (
                    <Chip
                      style={{ background: "rgb(1, 58, 78)", color: "white", fontSize: "0.7rem", maxHeight: "1.5rem" }}
                      key={langValue}
                      label={
                        <span>
                          <span style={{ marginRight: "4px", maxHeight: "0.5rem" }}>{langInfo.flag}</span>
                          {langInfo.label}
                        </span>
                      }
                    />
                  )
                );
              })}
            </div>
            <button
              type="button"
              className="section-control-btn"
              onClick={(e) => {
                e.stopPropagation();
                moveSectionUp(index);
              }}
              disabled={index === 0}
              title="Move Up"
            >
              ↑
            </button>
            <button
              type="button"
              className="section-control-btn"
              onClick={(e) => {
                e.stopPropagation();
                moveSectionDown(index);
              }}
              disabled={index === blogPost.sections.length - 1}
              title="Move Down"
            >
              ↓
            </button>
            <button
              type="button"
              className="section-control-btn remove"
              onClick={(e) => {
                e.stopPropagation();
                removeSection(section.id);
              }}
              disabled={blogPost.sections.length <= 1}
              title="Remove Section"
            >
              ×
            </button>
          </div>
        </div>

        {section.type === "text" ? (
  <ReactQuill 
    key={`${section.id}-${currentEditLanguage}`} // Add key that changes with language
    theme="snow" 
    value={(section.contents[currentEditLanguage] || "") as string}
    onChange={(content) => handleSectionChange(section.id, content)} 
    modules={quillModules} 
    placeholder="Enter your text here..." 
    className="section-quill-editor" 
  />
) : (
  <div className="image-section">
    <ImageUploader label="" value={typeof section.contents["image"] === "string" ? null : (section.contents["image"] as File)} onChange={(file) => handleSectionChange(section.id, file)} id={`section-image-${section.id}`} previewHeight={250} currentImageUrl={typeof section.contents["image"] === "string" ? section.contents["image"] : undefined} />
  </div>
)}
      </div>
    );
  };
  const handleLanguageChange = (language: string) => {
    setBlogPost({
      ...blogPost,
      // language: language,
    });
  };

  const renderPreview = () => {
    return (
      <div className="blog-preview" style={{ paddingBottom: "10rem" }}>
        <article className="post-preview">
          <h1 className="preview-post-title">{blogPost.title || "Untitled Post"}</h1>
          {blogPost.subtitle && <h2 className="preview-post-subtitle">{blogPost.subtitle}</h2>}

          {(blogPost.featuredImage || blogPost.featuredImageUrl) && <div className="preview-featured-image">{blogPost.featuredImage ? <img src={URL.createObjectURL(blogPost.featuredImage)} alt="Featured preview" /> : blogPost.featuredImageUrl ? <img src={blogPost.featuredImageUrl} alt="Featured preview" /> : null}</div>}

          <div className="preview-post-body">
            {blogPost.sections.map((section, index) => (
              <div key={section.id} className="preview-section">
                {section.type === "text" ? <div className="preview-text-section" dangerouslySetInnerHTML={{ __html: (section.contents[currentEditLanguage] as string) || "" }} /> : section.contents["image"] ? <div className="preview-image-section">{typeof section.contents["image"] === "string" ? <img src={section.contents["image"]} alt={`Section image ${index}`} className="preview-section-image" /> : <img src={URL.createObjectURL(section.contents["image"] as File)} alt={`Section image ${index}`} className="preview-section-image" />}</div> : null}
              </div>
            ))}
          </div>
        </article>
      </div>
    );
  };

  // If not authenticated, show password form
  if (!isAuthenticated) {
    return (
      <div className="blog-editor-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {renderPasswordForm()}
      </div>
    );
  }

  // If fetching post, show loading
  if (isEditMode && fetchingPost) {
    return (
      <div className="blog-editor-container">
        <div className="editor-loading">Loading post data...</div>
      </div>
    );
  }

  return (
    <div className="blog-editor-container">
      <div className="editor-panel">
        <div className="editor-header" style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ minWidth: "100%", display: "flex", flexDirection: "row", maxHeight: "2rem", justifyContent: "space-between", padding: "2rem 0rem", alignItems: "center" }}>
            <h1 className="editor-title">{isEditMode ? "Edit Blog Post" : "Create New Blog Post"}</h1>

            <div className="button-group">
              <button type="button" className="draft-button" onClick={saveDraft} disabled={isSaving}>
                {isSaving && isDraft ? "Saving..." : isEditMode ? "Update as Draft" : "Save as Draft"}
              </button>
              <button type="button" className="submit-button" onClick={handleSubmit} disabled={isSaving}>
                {isSaving && !isDraft ? "Publishing..." : isEditMode ? "Update & Publish" : "Publish"}
              </button>
            </div>
          </div>
          <div style={{ minWidth: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <FormControl fullWidth sx={{ mt: 2, maxWidth: "10rem" }}>
              <InputLabel id="current-edit-language-label">Current Editing Language</InputLabel>
              <Select labelId="current-edit-language-label" id="current-edit-language-select" value={currentEditLanguage} onChange={(e) => setCurrentEditLanguage(e.target.value)} label="Current Editing Language" disabled={selectedLanguages.length === 0}>
                {selectedLanguages.map((langValue) => {
                  const langInfo = languages.find((lang) => lang.value === langValue);
                  return (
                    langInfo && (
                      <MenuItem key={langValue} value={langValue}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "8px" }}>{langInfo.flag}</span>
                          {langInfo.label}
                        </span>
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <MultiLanguageDropdown />

        {apiError && <div className="api-error-message">{apiError}</div>}

        {successMessage && (
          <div className="success-message">
            {successMessage}
            {(createdPostId || originalPost?.id) && (
              <div className="post-link">
                <p>Your post is{isEditMode ? " still" : " now"} available at:</p>
                <a href={`/news/${blogPost.slug}`} target="_blank" rel="noopener noreferrer" className="view-post-link">
                  View Post
                </a>
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit} className="blog-form">
          <div className="form-group">
            <label htmlFor="title">Title*</label>
            <input type="text" id="title" name="title" value={blogPost.title} onChange={handleTextChange} className={errors.title ? "error" : ""} />
            {errors.title && <span className="error-message">{errors.title}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="slug">
              URL Slug* <span className="slug-hint">(Used in the URL: /news/your-slug)</span>
            </label>
            <div className="slug-input-wrapper">
              <span className="slug-prefix">/news/</span>
              <input type="text" id="slug" name="slug" value={blogPost.slug} onChange={handleTextChange} className={errors.slug ? "error" : ""} placeholder="your-post-slug" />
            </div>
            {errors.slug && <span className="error-message">{errors.slug}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="subtitle">Subtitle</label>
            <input type="text" id="subtitle" name="subtitle" value={blogPost.subtitle} onChange={handleTextChange} />
          </div>

          <div className="form-group">
            <label>Featured Image</label>
            <ImageUploader label="" value={blogPost.featuredImage} onChange={(file) => setBlogPost({ ...blogPost, featuredImage: file })} id="featuredImage" previewHeight={200} currentImageUrl={blogPost.featuredImageUrl} />
          </div>

          <div className="content-sections">
            <div className="sections-header">
              <h3>Content Sections</h3>
              {errors.body && <span className="error-message">{errors.body}</span>}
            </div>

            {blogPost.sections.map((section, index) => renderSection(section, index))}

            <div className="add-section-controls">
              <button type="button" className="add-section-btn text" onClick={() => addSection("text")}>
                + Add Text Section
              </button>
              <button type="button" className="add-section-btn image" onClick={() => addSection("image")}>
                + Add Image Section
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="preview-panel">
        <h2 className="preview-heading">Preview</h2>
        {renderPreview()}
      </div>
    </div>
  );
};

export default BlogEditor;
