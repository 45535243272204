import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultLanguage, getTranslation } from "../translation";
import "../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps, NewsItem } from "../interfaces";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { url } from "inspector";
import {
  Gavel, // For Administrative/Regulatory
  Business, // For Business/Corporate
  Balance, // For Litigation/Dispute
  FamilyRestroom, // For Family Law
  Handshake, // For Non-Profit
  Factory, // For Industry-Specific
  Copyright, // For Intellectual Property
} from "@mui/icons-material";

export default function LegalAreas({ isInHomepageView }: genericComponentProps) {
  const [currentView, setCurrentView] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });

  const LegalCategories: React.FC = () => {
    const categories = [
      {
        key: "Administrative_Public_and_Regulatory_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/DALL·E-2024-12-20-14.05.46-A-minimalistic-vector-style-icon-for-Administrative-Public-and-Regulatory-Law.-The-icon-features-a-simple-sharp-edged-book-design-with-clean-lines.webp",
        icon: <Gavel style={{ fontSize: "3rem" }} />, // Represents law, regulation, and authority
      },
      {
        key: "Business_Corporate_and_Financial_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/DALL·E-2024-12-20-14.19.08-A-minimalistic-vector-style-icon-for-Business-Corporate-and-Financial-Law.-The-icon-features-a-sharp-edged-briefcase-design-with-clean-lines-in-the.webp",
        icon: <Business style={{ fontSize: "3rem" }} />, // Represents business and corporate entities
      },
      {
        key: "Litigation_Dispute_Resolution_and_Enforcement_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/3.webp",
        icon: <Balance style={{ fontSize: "3rem" }} />, // Represents justice, balance, fairness in disputes
      },
      {
        key: "Family_and_Inheritance_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/DALL·E-2024-12-20-14.28.57-A-minimalistic-vector-style-icon-for-Family-and-Inheritance-Law.-The-icon-features-a-clean-and-sharp-edged-design-of-a-family-two-adults-and-a-child.webp",
        icon: <FamilyRestroom style={{ fontSize: "3rem" }} />, // Represents family relationships
      },
      {
        key: "Non_Profit_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/5.webp",
        icon: <Handshake style={{ fontSize: "3rem" }} />, // Represents cooperation, charitable work
      },
      {
        key: "Industry_Specific_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/6.webp",
        icon: <Factory style={{ fontSize: "3rem" }} />, // Represents various industries
      },
      {
        key: "Intellectual_Property_Technology_and_Digital_Law",
        image: "https://dotalive.org/wp-content/uploads/2024/12/7.webp",
        icon: <Copyright style={{ fontSize: "3rem" }} />, // Represents intellectual property protection
      },
    ];
    const [isHovered, setIsHovered] = React.useState("");
    return (
      <div id="container_legal">
        {categories.map(({ key, image, icon }) => {
          return (
            <div className="container_legal_item" onMouseEnter={() => setIsHovered(key)} onMouseLeave={() => setIsHovered("")}>
              {isHovered === key ? (
                <>
                  <ul style={{padding:'0rem 1rem', color:'white'}}>
                    {Object.keys(getTranslation(`${key}.categories`)).map((subcategoryKey) => (
                      <li key={subcategoryKey}>
                        {getTranslation(`${key}.categories.${subcategoryKey}`)}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {" "}
                  <span className="legal_item_icon">{icon}</span>
                  <h2 style={{ textAlign: "left", margin: "0px", padding: "0px" }}>{getTranslation(`${key}.title`)}</h2>
                </>
              )}

              {/* <div key={key} className="column_legal_inner">
           <img src={image} alt={getTranslation(`${key}.title`)} />
           <div className="content">
             <ul className="list">
               {Object.keys(getTranslation(`${key}.categories`)).map(
                 (subcategoryKey) => (
                   <li key={subcategoryKey} className="listItem">
                     {getTranslation(`${key}.categories.${subcategoryKey}`)}
                   </li>
                 )
               )}
             </ul>
           </div>
         </div> */}
            </div>
          );
        })}
      </div>
    );
  };

  const styles = {
    list: {
      // padding: '0',
      margin: "0",
      // listStyleType: 'none',
    },
    listItem: {
      marginBottom: "8px",
      fontSize: "14px",
      color: "#555",
    },
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // Remove border
                // borderBottomLeftRadius: '20px', // Add border radius to bottom-left
                // borderBottomRightRadius: '20px', // Add border radius to bottom-right
                boxShadow: "0px 16px 12px -12px rgba(0, 0, 0, 0.2)", // Box shadow at the bottom
              },
              "&.Mui-focused fieldset": {
                border: "1px solid gray", // Add gray border when focused
                borderBottomLeftRadius: "20px", // Maintain border radius on bottom-left when focused
                borderBottomRightRadius: "20px", // Maintain border radius on bottom-right when focused
              },
            },
          },
        },
      },
    },
  });
  const handleMouseEnter = (event: any) => {
    let countryName = "See Location";

    // Replace underscores with spaces

    // setTimeout(() => {
    setTooltip({
      visible: true,
      content: countryName,
      x: event.clientX + 30,
      y: event.clientY,
    });
    // }, 1000);
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  function WorldMap2() {
    return <></>;
  }

  return (
    <div id="about_us" style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <div style={{ fontSize: "3.5rem", color: "rgb(1, 58, 78)" }}>
        {getTranslation("legal_areas.header")}
        <hr
          style={{
            background: "#013A4E",
            height: "2px",
            margin: "0.3rem 0rem 0rem 0rem",
            width: "5.5rem",
          }}
        />
      </div>
      <div
        style={{
          // height: "75vh",
          color: "rgb(1, 58, 78)",
          display: "flex",
        }}
      >
        <span>{getTranslation("legal_areas.subheading")}</span>
      </div>
      <LegalCategories />
    </div>
  );
}
