import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultLanguage, getTranslation } from "../translation";
import "../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps, NewsItem } from "../interfaces";
import CurrentMap from "./CurrentMap";

export default function About({ isInHomepageView }: genericComponentProps) {
  const [currentView, setCurrentView] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });

  return (
    <div id="about_us">
    {/* Add the sticky-header class to make the header sticky */}
    <div className="sticky-header">
      <span style={{ fontSize: "3.5rem", color: '#013A4E' }}>
        {getTranslation("about_us.header")}
        <hr className="heading_buttom_line" />
      </span>
    </div>
    
    <div className="about_us_container">
      {/* Content section */}
      <div className="about-us-content">
        <div className="about-us-section" style={{paddingBottom:'30px', marginBottom:'0px', borderBottom:'1px solid #e0e0e0'}}>
          <p className="about-us-paragraph">{getTranslation("about_us.established")}</p>
          <p className="about-us-paragraph">{getTranslation("about_us.mission")}</p>
        </div> 
      </div>
      
      {/* Map section - already has sticky positioning applied via CSS */}
      <div className="about_us_map">
        <CurrentMap animation={true}/>
      </div>
      
      {/* Tooltip */}
      {tooltip.visible && (
        <div
          style={{
            padding: "0.3rem 0.5rem",
            background: "white",
            margin: "0px",
            color: "black",
            fontWeight: "bold",
            borderRadius: "20px",
            position: "absolute",
            left: tooltip.x,
            top: tooltip.y,
          }}
        >
          {tooltip.content}
        </div>
      )}
    </div>

    <div className="about-us-section">
          <h2 className="about-us-subtitle">{getTranslation("about_us.authorization_title")}</h2>
          <p className="about-us-paragraph">{getTranslation("about_us.authorization_text")}</p>
          <ul className="about-us-list">
            <li className="about-us-list-item">{getTranslation("about_us.service_registrar")}</li>
            <li className="about-us-list-item">{getTranslation("about_us.service_property")}</li>
          </ul>
        </div>
  
    
   <div className="about-us-all">
 <div className="about-us-section" style={{borderBottom:'none', paddingBottom:'0px',marginBottom:'0px'}}>
          <h2 className="about-us-subtitle">{getTranslation("about_us.communication_title")}</h2>
          <p className="about-us-paragraph">{getTranslation("about_us.communication_text")}</p>
          <ul className="about-us-list about-us-languages">
            <li className="about-us-list-item">{getTranslation("about_us.language_macedonian")}</li>
            <li className="about-us-list-item">{getTranslation("about_us.language_english")}</li>
            <li className="about-us-list-item">{getTranslation("about_us.language_german")}</li>
            <li className="about-us-list-item">{getTranslation("about_us.language_serbian")}</li>
            <li className="about-us-list-item">{getTranslation("about_us.language_croatian")}</li>
          </ul>
        </div>
        <div className="about-us-conclusion">
          <p className="about-us-paragraph about-us-highlight">{getTranslation("about_us.commitment")}</p>
        </div>
   </div>
             <p className=" about-us-section about-us-paragraph" style={{borderBottom:'none', paddingBottom:'0px',marginBottom:'0px'}}>{getTranslation("about_us.translators")}</p>

        <div className="about-us-section" style={{paddingBottom:'5rem'}}>
          <p className="about-us-paragraph">{getTranslation("about_us.collaborations")}</p>
          <p className="about-us-paragraph">{getTranslation("about_us.vienna_contact")}</p>
        </div>
        
       
  </div>
  );
}
