import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultLanguage, getDefaultLanguage, getTranslation } from "../translation";
import "../Home.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChatIcon from "@mui/icons-material/Chat";
import { ReactComponent as WorldMap } from "./../img/world.svg";
import CurrentMap from "./CurrentMap";

type Segment = "booking" | "services" | "about" | "prices" | "news";

function Home({ isMenuOpened }: any): JSX.Element {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const textIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  const [currentLanguage, setCurrentLanguage] = useState(getDefaultLanguage());
  const animationSetupRef = useRef(false);

  const [mapAnimationTrigger, setMapAnimationTrigger] = useState(false);

useEffect(() => {
  if (currentView === 1) {
    // Trigger reanimation for the map
    setMapAnimationTrigger(false); // Reset first
    setTimeout(() => {
      setMapAnimationTrigger(true);
    }, 50); // Slight delay to ensure effect runs
    
  }
}, [currentView]);

  useEffect(() => {
    const newLanguage = getDefaultLanguage();
    if (newLanguage !== currentLanguage) {
      setCurrentLanguage(newLanguage);
    }
  }, [getDefaultLanguage()]);
  useEffect(() => {
    // Delay to ensure DOM elements are ready
    const timer = setTimeout(setupWordAnimation, 500);
  
    // Return cleanup function
    return () => {
      clearTimeout(timer);
      if (textIntervalRef.current) {
        clearInterval(textIntervalRef.current);
        textIntervalRef.current = null;
      }
    };
  }, [currentView, isMenuOpened, currentLanguage]); // Added currentLanguage
  // Full updated view structure with unique class names
  const views = [
   
    {
      id: "first_view",
      content: (
        <>
          <div className="hero-slide-container">
            <div className="hero-content-wrapper">
              <div className="hero-text-container">
                {/* New animated text component */}
                <div className="new-rotating-container">
                  <div className="new-text-wrapper">
                    {/* Left side - Static text */}
                    <div className="static-text-container">{getTranslation("home.static_text")}</div>
  
                    {/* Right side - Rotating words */}
                    <div className="new-dynamic-container">
                      <div className="new-words-wrapper">
                        <span id="word1" className="new-rotating-word active new-color-primary">
                          {getTranslation("home.rotating_word1")}
                        </span>
                        <span id="word2" className="new-rotating-word new-color-secondary">
                          {getTranslation("home.rotating_word2")}
                        </span>
                        <span id="word3" className="new-rotating-word new-color-accent">
                          {getTranslation("home.rotating_word3")}
                        </span>
                        <span id="word4" className="new-rotating-word new-color-highlight">
                          {getTranslation("home.rotating_word4")}
                        </span>
                        <span id="word5" className="new-rotating-word new-color-emphasis">
                          {getTranslation("home.rotating_word5")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
  
                <p className="hero-description">{getTranslation("home.hero_description")}</p>
                <p className="hero-description">{getTranslation("home.hero_description_2")}</p>
              </div>
  
              <div className="hero-button-wrapper">
              <button onClick={() => {
                   if(currentLanguage==="en") {
                    navigate("/about-us")
                  } else {
                    navigate("/за-нас")
                  }
                }}  className="hero-action-button">
                  {getTranslation("read_more")}
                </button>
              </div>
            </div>
            <div className="hero-right-section"></div>
          </div>
        </>
      ),
    },
    {
      id: "second_view",
      content: (
        <>
        <div className="view-wrapper">
          <div className="hero-slide-container-map">
            <div className="hero-content-wrapper">
              <div className="hero-text-container">
                <div className="new-rotating-container">
                  <div className="new-text-wrapper">
                    <p className="static-text-container map-text mobile_new" style={{ padding: 0, margin: 0 }}>
                      {getTranslation("second_view.title1")}
                    </p>
                  </div>
                  <div className="new-text-wrapper">
                    <p className="static-text-container map-text mobile_new" style={{ padding: 0, margin: 0 }}>
                      {getTranslation("second_view.title2")}
                    </p>
                  </div>
                </div>
                <p className="hero-description map-text">{getTranslation("second_view.description")}</p>
              </div>
      
              {/* <div className="hero-button-wrapper">
                <button
                  onClick={() => {
                    if (currentLanguage === "en") {
                      navigate("/legal-areas");
                    } else {
                      navigate("/правна-страна");
                    }
                  }}
                  className="hero-action-button"
                >
                  {getTranslation("read_more")?.charAt(0).toUpperCase() +
                    getTranslation("read_more")?.slice(1).toLowerCase()}
                </button>
              </div> */}
            </div>
            <div className="hero-right-section"></div>
          </div>
          <div className="hero-slide-container mobile_hide">
            <div className="hero-content-wrapper" style={{maxHeight:'65%', marginTop:'-4rem', opacity:'0.8'}}>
            <CurrentMap animation={mapAnimationTrigger} />

            </div>
          </div>
      
        </div>
      </>
      
      ),
    },
    {
      id: "third_view",
      content: (
        <>
          <div className="hero-slide-container">
            <div className="hero-content-wrapper">
              <div className="hero-text-container">
                {/* New animated text component */}
                <div className="new-rotating-container">
                  <div className="new-text-wrapper">
                  <p className="static-text-container mobile_new" style={{ padding: "0px", margin: '0px' }}>
                  {getTranslation("third_view.title")}</p>
                  </div>
                </div>
  
                <p className="hero-description">{getTranslation("third_view.description")}</p>
              </div>
  
              <div className="hero-button-wrapper">
                <button onClick={() => {
                   if(currentLanguage==="en") {
                    navigate("/our-team")
                  } else {
                    navigate("/нашиот-тим")
                  }
                }} className="hero-action-button">
                  {getTranslation("read_more")?.charAt(0).toUpperCase() + getTranslation("read_more")?.slice(1).toLowerCase()}
                </button>
              </div>
            </div>
            <div className="hero-right-section"></div>
          </div>
        </>
      ),
    }
  ];
  const renderVerticalNav = () => {
    return (
      <div className="vertical-nav">
        {views.map((_, index) => (
          <div key={index} className={`nav-dot ${currentView === index ? "active" : ""}`} onClick={() => handleChangeView(index)}></div>
        ))}
      </div>
    );
  };

  // Make sure animation starts with the first slide visible
  useEffect(() => {
    const firstWord = document.getElementById("word1");
    if (firstWord) {
      firstWord.style.opacity = "1";
    }
  }, []);

  const startInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Use different timing for the first view (15 seconds) vs other views (7 seconds)
    const intervalTime = currentView === 0 ? 12000 : 12000;

    intervalRef.current = setInterval(() => {
      setCurrentView((prev) => (prev + 1) % views.length);
    }, intervalTime);
  };

  // Function to set up animation for the rotating text
  const setupWordAnimation = () => {
    // Clear any existing text animation interval
    if (textIntervalRef.current) {
      clearInterval(textIntervalRef.current);
      textIntervalRef.current = null;
    }

    // Only set up animation if we're on the first slide
    if (currentView !== 0) return;

    // Get all word elements
    const words = [
      document.getElementById("word1"), 
      document.getElementById("word2"), 
      document.getElementById("word3"), 
      document.getElementById("word4"), 
      document.getElementById("word5")
    ].filter(Boolean);

    if (words.length === 0) return;

    // Process each word to split into letters
    words.forEach((word) => {
      if (!word) return;

      // Clear existing content before processing
      word.innerHTML = '';
      
      // Get the original text from translations or defaults
      let text = '';
      const wordId = word.id;
      switch(wordId) {
        case 'word1': 
          text = getTranslation("home.rotating_word1") || "JUSTICE";
          break;
        case 'word2': 
          text = getTranslation("home.rotating_word2") || "EXCELLENCE";
          break;
        case 'word3': 
          text = getTranslation("home.rotating_word3") || "INTEGRITY";
          break;
        case 'word4': 
          text = getTranslation("home.rotating_word4") || "COMMITTED";
          break;
        case 'word5': 
          text = getTranslation("home.rotating_word5") || "RESULTS";
          break;
      }

      // Process each character including spaces
      // @ts-ignore
      [...text].forEach((char) => {
        const span = document.createElement("span");

        if (char === " ") {
          // Create a non-breaking space for better animation
          span.innerHTML = "&nbsp;";
          span.className = "letter space";
        } else {
          span.textContent = char;
          span.className = "letter";
        }

        word.appendChild(span);
      });
    });

    // Reset all words to their initial state
    words.forEach((word, index) => {
      if (!word) return;
      word.style.opacity = index === 0 ? "1" : "0";
      
      Array.from(word.children).forEach((letter) => {
        // Keep the "space" class if present
        if (letter.classList.contains("space")) {
          letter.className = "letter space";
        } else {
          letter.className = "letter";
        }
      });
    });

    // Set up the rotation
    let currentWordIndex = 0;
    const maxWordIndex = words.length - 1;

    const rotateText = () => {
      // If we've navigated away from the first slide, clear the interval
      if (currentView !== 0) {
        if (textIntervalRef.current) {
          clearInterval(textIntervalRef.current);
          textIntervalRef.current = null;
        }
        return;
      }

      const currentWord = words[currentWordIndex];
      const nextWord = currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];

      if (!currentWord || !nextWord) return;

      // Rotate out letters of current word
      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          // Keep the existing class if it's a space
          if (letter.classList.contains("space")) {
            letter.className = "letter space out";
          } else {
            letter.className = "letter out";
          }
        }, i * 80);
      });

      // Set next word to visible
      nextWord.style.opacity = "1";

      // Reset other words to invisible
      words.forEach((word, i) => {
        if (i !== currentWordIndex && i !== (currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1)) {
          // @ts-ignore
          word.style.opacity = "0";
        }
      });

      // Rotate in letters of next word
      Array.from(nextWord.children).forEach((letter, i) => {
        // Keep the existing class if it's a space
        if (letter.classList.contains("space")) {
          letter.className = "letter space behind";
        } else {
          letter.className = "letter behind";
        }

        setTimeout(() => {
          // Keep the existing class if it's a space
          if (letter.classList.contains("space")) {
            letter.className = "letter space in";
          } else {
            letter.className = "letter in";
          }
        }, 340 + i * 80);
      });

      // Update current word index
      currentWordIndex = currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };

    // Initial call and interval setup
    rotateText();
    textIntervalRef.current = setInterval(rotateText, 3000);
  };

  // Make sure interval is reset when currentView changes
  useEffect(() => {
    startInterval();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [currentView, views.length]); // Add currentView to the dependency array

  // Set up animation when component mounts, when currentView changes, or when isMenuOpened changes
  useEffect(() => {
    // Delay to ensure DOM elements are ready
    const timer = setTimeout(setupWordAnimation, 500);

    // Return cleanup function
    return () => {
      clearTimeout(timer);
      if (textIntervalRef.current) {
        clearInterval(textIntervalRef.current);
        textIntervalRef.current = null;
      }
    };
  }, [currentView, isMenuOpened]); // Add isMenuOpened to the dependency array

  const handleChangeView = (index: number) => {
    setCurrentView(index);
    startInterval();
  };

  // Set up interval for changing slides
  useEffect(() => {
    startInterval();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [views.length]);

  function handleMouseEnter(event: any) {
    let countryName = event.target.classList[0];
    if (countryName === undefined || countryName.length === 0) {
      countryName = event.target.getAttribute("name");
    }

    const allowedCountries = ["Venezuela", "Panama", "United States", "Canada", "Angola", "United Arab Emirates", "Azerbaijan", "Indonesia", "India", "Pakistan", "Israel", "Syria", "Palestine", "Qatar", "Turkey", "Kyrgyzstan", "Macedonia", "Greece", "Bulgaria", "Ukraine", "Sweden", "Denmark", "United Kingdom", "Netherlands", "Belgium", "Luxembourg", "France", "Germany", "Czech Republic", "Austria", "Switzerland", "Italy", "Portugal", "Serbia", "Romania", "Croatia", "Slovenia", "Bosnia and Herzegovina", "Montenegro", "Marshall Islands", "Australia", "New Zealand", "Spain", "Nigeria", "Greenland", "Cyprus", "Slovakia", "Hungary", "Poland", "Philippines", "Qatar", "Marshall Islands"];

    countryName = countryName?.replace(/_/g, " ");

    if (countryName && allowedCountries.includes(countryName)) {
      setTooltip({
        visible: true,
        content: countryName,
        x: event.clientX + 10,
        y: event.clientY - 80,
      });
    }
  }

  function handleMouseLeave() {
    setTooltip({ ...tooltip, visible: false });
  }

  return (
    <>
      {!isMenuOpened && (
        <div className="home-component">
          <div className="carousel-container">
            {views.map((view, index) => (
              <div key={view.id} className={`carousel-slide ${index === currentView ? "carousel-slide-active" : ""}`}>
                <div className="home_container">{view.content}</div>
              </div>
            ))}

            {renderVerticalNav()}

            {/* Envelope component with contact text */}
            <div className="letter-container">
              <div className="letter-image" onClick={() => navigate("/contact")}>
                <div className="animated-mail">
                  <div className="back-fold"></div>
                  <div className="letter">
                    <div className="letter-border"></div>
                    <div className="letter-title"></div>
                    <div className="letter-context"></div>
                    <div className="letter-text">Contact Us</div>
                    <div className="letter-stamp">
                      <div className="letter-stamp-inner"></div>
                    </div>
                  </div>
                  <div className="top-fold"></div>
                  <div className="body"></div>
                  <div className="left-fold"></div>
                </div>
                <div className="shadow"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
