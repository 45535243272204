import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import './../../styles/ImageUploader.css';

interface ImageUploaderProps {
  label: string;
  onChange: (file: File) => void;
  value: File | null;
  previewHeight?: number;
  id?: string;
  currentImageUrl?: string; // New prop for existing image URLs
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  label,
  onChange,
  value,
  previewHeight = 200,
  id,
  currentImageUrl
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Generate a unique ID if none is provided
  const inputId = id || `image-upload-${Math.random().toString(36).substr(2, 9)}`;

  // Set preview URL when either value or currentImageUrl changes
  useEffect(() => {
    if (value) {
      // If we have a File object, create a URL from it
      setPreviewUrl(URL.createObjectURL(value));
    } else if (currentImageUrl) {
      // If we have a URL string but no File, use the URL directly
      setPreviewUrl(currentImageUrl);
    } else {
      // If we have neither, clear the preview
      setPreviewUrl(null);
    }

    // Clean up any created object URLs when the component unmounts or when value changes
    return () => {
      if (value && previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [value, currentImageUrl]);
  
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  };
  
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files[0]);
    }
  };
  
  const handleSelectClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    // Call onChange with null to clear the image in the parent component
    onChange(null as unknown as File);
  };
  
  const renderPreview = () => {
    if (!previewUrl) return null;
    
    return (
      <div className="image-preview">
        <img 
          src={previewUrl} 
          alt="Upload preview" 
          style={{ maxHeight: `${previewHeight}px` }}
        />
        <div className="image-info">
          {value ? (
            <>
              <span className="image-name">{value.name}</span>
              <span className="image-size">{(value.size / 1024).toFixed(1)} KB</span>
            </>
          ) : (
            <span className="image-name">Current image</span>
          )}
        </div>
        <button 
          className="remove-image" 
          type="button" 
          onClick={handleRemoveImage}
          aria-label="Remove image"
        >
          ×
        </button>
      </div>
    );
  };
  
  return (
    <div className="image-uploader">
      {label && <label htmlFor={inputId} className="uploader-label">{label}</label>}
      
      <div 
        className={`uploader-dropzone ${isDragging ? 'dragging' : ''} ${previewUrl ? 'has-file' : ''}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleSelectClick}
      >
        {previewUrl ? renderPreview() : (
          <div className="upload-placeholder">
            <div className="upload-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17 8L12 3L7 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div className="upload-text">
              <p>Drag & drop an image here, or click to select</p>
              <p className="upload-hint">JPG, PNG or GIF • Max 5MB</p>
            </div>
          </div>
        )}
        
        <input
          type="file"
          id={inputId}
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="file-input"
        />
      </div>
    </div>
  );
};

export default ImageUploader;