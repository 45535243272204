import React, { useState, useEffect } from 'react';
import './../../styles/News.css';
import { setDefaultLanguage, getDefaultLanguage, getTranslation } from '../../translation';
type Language = "mk" | "en" | "de";

interface Section {
  id: number;
  type: 'text' | 'image';
  content: string;
  position: number;
  filename?: string;
}

interface BlogPost {
  id: number;
  title: string;
  subtitle: string;
  slug:string;
  featured_image_url: string | null;
  status: 'draft' | 'published';
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  sections: Section[];
}

interface PaginationInfo {
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
}

interface NewsProps {
  baseUrl?: string; // API base URL
}

const News: React.FC<NewsProps> = ({ baseUrl = 'https://server.dotalive.org/api' }) => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>({
    page: 1,
    pageSize: 10,
    totalPages: 1,
    totalItems: 0
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch posts when page changes
  useEffect(() => {
    fetchPosts(pagination.page);
  }, [pagination.page]);
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(getDefaultLanguage());



  // Function to fetch posts from the API
  const fetchPosts = async (page: number) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${baseUrl}/blog-posts?page=${page}&pageSize=${pagination.pageSize}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      
      const data = await response.json();
      
      setPosts(data.posts);
      setPagination({
        page: data.pagination.page,
        pageSize: data.pagination.pageSize,
        totalPages: data.pagination.totalPages,
        totalItems: data.pagination.totalItems
      });
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to load news articles. Please try again later.');
      setPosts([]);
    } finally {
      setLoading(false);
    }
  };

  // Function to generate excerpt from post content
  const generateExcerpt = (post: BlogPost): string => {
    // Find the first text section
    const textSection = post.sections.find(section => section.type === 'text');
    
    if (!textSection) return '';
    
    // Strip HTML tags and get plain text
    const plainText = textSection.content.replace(/<[^>]*>/g, '');
    
    // Limit to 150 characters and add ellipsis if needed
    return plainText.length > 150 ? plainText.substr(0, 150) + '...' : plainText;
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > pagination.totalPages) return;
    setPagination({ ...pagination, page: newPage });
  };

  // Format date for display
  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Render pagination controls
  const renderPagination = () => {
    const { page, totalPages } = pagination;
    
    // Calculate which page numbers to show
    const pageNumbers = [];
    const maxPagesToShow = 5;
    
    let startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;
    
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    return (
      <div className="news-pagination">
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(1)} 
          disabled={page === 1}
        >
          &laquo;
        </button>
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(page - 1)} 
          disabled={page === 1}
        >
          &lsaquo;
        </button>
        
        {pageNumbers.map(num => (
          <button 
            key={num}
            className={`pagination-button ${num === page ? 'active' : ''}`}
            onClick={() => handlePageChange(num)}
          >
            {num}
          </button>
        ))}
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(page + 1)} 
          disabled={page === totalPages}
        >
          &rsaquo;
        </button>
        
        <button 
          className="pagination-button"
          onClick={() => handlePageChange(totalPages)} 
          disabled={page === totalPages}
        >
          &raquo;
        </button>
      </div>
    );
  };

  return (
    <div className="news-container" style={{paddingBottom:'10rem'}}>
 <span style={{ fontSize: "3.5rem", color: "#013A4E", paddingBottom:'2rem' }}>
              {getTranslation("news.header")}
              <hr
                style={{
                  background: "#013A4E",
                  height: "2px",
                  margin: "0.3rem 0rem 0rem 0rem",
                  width: "5.5rem",
                }}
              />
            </span>      
      {loading && <div className="news-loading">Loading news articles...</div>}
      
      {error && <div className="news-error">{error}</div>}
      
      {!loading && !error && posts.length === 0 && (
        <div className="news-empty">No news articles found.</div>
      )}
      
      <div className="news-grid" style={{marginTop:'2rem'}}>
      {posts.map(post => (
        <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}/${post.slug}/${getTranslation('news.header')=== "News" ? 'en':'mk'}`} style={{textDecoration:'none'}}>
  <article key={post.id} className="news-card">
    {post.featured_image_url ? (
      <div className="news-card-image">
        <img src={post.featured_image_url} alt={post.title} />
      </div>
    ) : (
      <div className="news-card-image placeholder">
        <div className="placeholder-text">No Image</div>
      </div>
    )}
    
    <div className="news-card-content">
      <div>
        <h2 className="news-card-title">
          <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}/${post.slug}/${getTranslation('news.header')=== "News" ? 'en':'mk'}`}>{post.title}</a>
        </h2>
        
        <div className="news-card-excerpt">
          {generateExcerpt(post)}
        </div>
      </div>
      
      <div className="news-card-meta">
        {/* <span className="news-card-date"> */}
          {/* {formatDate(post.published_at)} */}
        {/* </span> */}
        <a href={`/${getTranslation('news.header')=== "News" ? 'news':'новости'}/${post.slug}/${getTranslation('news.header')=== "News" ? 'en':'mk'}`} className="news-card-read-more">
          Read More
        </a>
      </div>
    </div>
  </article>
  </a>
))}
      </div>
      
      {!loading && !error && pagination.totalPages > 1 && renderPagination()}
      
      {!loading && !error && posts.length > 0 && (
        <div className="news-page-info">
          Showing {posts.length} of {pagination.totalItems} articles
        </div>
      )}
    </div>
  );
};

export default News;