import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import { setDefaultLanguage, getTranslation } from "./../../translation";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import Alert from "@mui/material/Alert/Alert";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Calendar from "react-calendar";
// import 'react-calendar/dist/Calendar.css';
import "../../styles/Calendar.css";
import YouTube from 'react-youtube';
import "../../styles/Reservation.css";
import "../../styles/AdminWelcome.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEdit from "@mui/icons-material/ModeEdit"
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import Checkbox from 'material-ui/Checkbox';

import { format } from "date-fns";
import BookingComponent from "./BookingComponent";
import PackagesComponent from "./PackagesComponent ";
import ServicesComponent from "./ServicesComponent ";
import NewsComponent from "./NewsComponent ";
import NewsDetailsComponent from "./NewsDetailsComponent";
import { NewsItem, PackageItem, ServiceItem, FormValues,ApproveBooking } from "../../interfaces";
import { HardwareMemory } from "material-ui/svg-icons";
import { EditLocation } from "@mui/icons-material";
import { UserInfo } from "os";
import TimeSlots from "../../components/TimeSlots";

interface TimeSlot {
  time: string;
  booked: boolean;
  selected: boolean;
}

interface AdminTimeSlot {
  time: string;
  booked: boolean;
  selected: boolean;
  booking_info: BookingInfo;
}

interface BookingInfo {
  booking_id: number | null;
  user_email: string;
  services: string[]; // Assuming the services are stored as an array of strings
  packages: string[]; // Assuming the packages are stored as an array of strings
  status: number;
  timeslot: string;
}

interface addUserBooking {
  user:FormValues,
  packages: string[],
  services: string[],
  date:string,
  timeslots:TimeSlot[],
  // Add more properties as needed
}

interface CurrentTimeSlot {
  time:string,
  booked:boolean,
  selected:boolean,
  booking_info: BookingInfo;
}

type Step = "booking" | "packages" | "contact_form" | "thank_you";
interface HomepageProps {}




const AdminWelcome: React.FC<HomepageProps> = () => {
  const navigate = useNavigate();
  const openCreateView = React.useRef(null);
  const openCreatePackageView = React.useRef(null);
  const [currentStep, setCurrentStep] = useState<Step>("booking");
  const [isSetCreateNews, setIsSetCreateNews] = useState<Boolean>(false);
  const[userBookingInfo, setUserBookingInfo] = useState<addUserBooking>({
    date:"",
    packages:[],
    services:[],
    timeslots:[],
    user:{
      email:"",
      name:"",
      phone:"",
      subject:"",
      surname:""
    }
  })
  const [isSetCreateServices, setIsSetCreateServices] = useState<Boolean>(
    false
  );
  const [allBookings, setAllBookings] = useState<CurrentTimeSlot[]>([]);
  const [currentBooking, setCurrentBooking] = useState<AdminTimeSlot[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [initialAdminTimeslots, setAdminTimeSlotsd] = useState<AdminTimeSlot[]>([
    {
      booked: false,
      selected: false,
      time: "10-11",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "11-12",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "12-13",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "13-14",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "14-15",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "15-16",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "16-17",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "17-18",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
  ]);
  const [adminTimeSlots, setAdminTimeSlots] = useState<AdminTimeSlot[]>([
    {
      booked: false,
      selected: false,
      time: "10-11",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "11-12",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "12-13",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "13-14",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "14-15",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "15-16",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "16-17",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
    {
      booked: false,
      selected: false,
      time: "17-18",
      booking_info:{
        booking_id:null,
        packages:[],
        services:[],
        status:0,
        timeslot:'',
        user_email:''
      },
    },
  ]);

  function removeLeadingZeros(dateString: string) {
    return dateString.replace(/^0+/, "");
  }

  const fetchTimeSheet = async () => {
    try {
      let date = format(startDate as Date, "MM-dd-yyyy");

      const dateObj = new Date(startDate as Date);
      const month = dateObj.getMonth() + 1; // Adding 1 because months are zero-based.
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();

      // const response = await fetch(`https://numbers.nikolakolevski.com/gettimesheet/${date}`);
      const response = await fetch(
        `https://numbers.nikolakolevski.com/admin/gettimesheet/${month + "-" + day + "-" + year}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch time sheet");
      }
      // resetCheckbox()
      // ;
      const fetchedBookedTimes = await response.json();

      if (fetchedBookedTimes.length > 0) {
        const updatedTimeSlots: AdminTimeSlot[] = adminTimeSlots.map((slot) => {
          return { ...slot, booked: true };
        });

        const updatedAdminTimeSlots = adminTimeSlots.map((adminTimeSlot) => {
          const foundBooking = fetchedBookedTimes.find((booking:any) => booking.timeslot === adminTimeSlot.time);
          return {
            ...adminTimeSlot,
            booked: !!foundBooking,
            booking_info: foundBooking ? { ...foundBooking } : adminTimeSlot.booking_info,
          };
        });

        // Now set the updatedAdminTimeSlots in your state
        // 
        setAdminTimeSlots(updatedAdminTimeSlots);


        // Set the updated timeSlots in the state
        setCurrentBooking(initialAdminTimeslots);
      } else {
        setAdminTimeSlots(initialAdminTimeslots);
        console.log(adminTimeSlots);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: date,
      }));

      setDateChanged(true);
    } catch (error) {
      // setError(error.message);
    }
  };
  const playerOpts = {
    width: '100%', // Set your desired width here
  };

  const handleOpenCreateNews = () => {
    setIsSetCreateNews(true);
    console.log("-------------------------------------");
  };
  const handleCloseCreateNews = () => {
    setIsSetCreateNews(false);
    console.log("-------------------------------------");
  };

  const handleOpenCreateServices = () => {
    setIsSetCreateServices(true);
    console.log("-------------------------------------");
  };
  const handleCloseCreateServices = () => {
    setIsSetCreateServices(false);
    console.log("-------------------------------------");
  };

  const setClickedTime = (currentTimeSlot: TimeSlot) => {
    // setAllSelected(true);
    const updatedTimeSlots: AdminTimeSlot[] = adminTimeSlots.map((slot) => {
      if (slot.time == currentTimeSlot.time) {
        return { ...slot, selected: !slot.selected };
      }
      return slot;
    });
    setAdminTimeSlots(updatedTimeSlots);
  };

  const [formData, setFormData] = useState<FormValues>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    subject: "",
  });



  const handleSubmitReservation = (e: FormEvent) => {
    e.preventDefault();

    // Check if all fields have a value
    const allFieldsFilled = Object.values(formData).every(
      (value) => value !== ""
    );
    if (!allFieldsFilled) {
      // Display an error or handle the scenario where not all fields are filled
      handleOpen();
      setToastMessage("empty_fields");
      return;
    }

    sendBookingForm();
    // Send the form data to the API as a JSON object
  };

  const returnSelectedTimeSlots = () => {
    const selectedTimeSlots = adminTimeSlots
      .map((slot) => {
        if (slot.selected) {
          return slot.time;
        }
        return null; // Return null instead of empty object
      })
      .filter((slot) => slot !== null) as string[]; // Filter out null values
    return selectedTimeSlots;
  };

  const returnSelectedBooking = () => {
    let bi = {
      booking_id:"",
      user_email:""
    } as ApproveBooking
    for(let i =0 ; i < adminTimeSlots.length; i ++) {
    //  
    if(adminTimeSlots[i].selected) {
      bi.booking_id = String(adminTimeSlots[i].booking_info.booking_id)
      bi.user_email= adminTimeSlots[i].booking_info.user_email
      return bi
    }
   }
    // return selectedTimeSlots;
  };

  const sendBookingForm = async () => {
    try {
        // formData.timeSlots = selectedTimeSlots
        // const response = await fetch('https://numbers.nikolakolevski.com/user-booking', {
        const response = await fetch('https://numbers.nikolakolevski.com/user-booking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userBookingInfo),
      })
        .then((response) => {

          resetUserBookingInfo()
          fetchTimeSheet()
          handleCloseDialog();
        })
        .catch((error) => {
          // Handle any errors
          // ...
        });


    } catch (error) {
      // setError(error.message);
    }
  };

  const addService = (newService: string | undefined) => {
    const updatedServices = [
      ...(userBookingInfo?.services ?? []), // Add existing services or initialize an empty array
      ...(newService ? [newService] : []), // Add the new service only if it's not undefined
    ];
    setUserBookingInfo(prevUserBookingInfo => ({
      ...prevUserBookingInfo, // Keep the existing values of other properties
      services: updatedServices // Update the services property
    }));
  };

  const removeService = (serviceToRemove: string) => {
    const list = (userBookingInfo?.services ?? []).filter(service => service !== serviceToRemove);
    setUserBookingInfo(prevUserBookingInfo => ({
      ...prevUserBookingInfo, // Keep the existing values of other properties
      services: list // Update the services property
    }));
  };

  const addPackage = (newPackage: string | undefined) => {
    const updatedPackages = [
      ...(userBookingInfo?.packages ?? []), // Add existing services or initialize an empty array
      ...(newPackage ? [newPackage] : []), // Add the new service only if it's not undefined
    ];
    // updatePackages(updatedPackages);
    setUserBookingInfo(prevUserBookingInfo => ({
      ...prevUserBookingInfo, // Keep the existing values of other properties
      packages: updatedPackages // Update the services property
    }));
  };

  const removePackage = (packageToRemove: string) => {
    const list = (userBookingInfo?.packages ?? []).filter(packagetmp => packagetmp !== packageToRemove);
    setUserBookingInfo(prevUserBookingInfo => ({
      ...prevUserBookingInfo, // Keep the existing values of other properties
      packages: list // Update the services property
    }));
  };

  const [toast_message, setToastMessage] = useState("empty_fields");

  const initialUserBookingInfo = {
    date: "",
    packages: [],
    services: [],
    timeslots: [],
    user: {
      email: "",
      name: "",
      phone: "",
      subject: "",
      surname: ""
    }
  };

  const resetUserBookingInfo = () => {
    setUserBookingInfo(initialUserBookingInfo);
  };


  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteOpen(true);
  };
  const [isCreatePackageOpen, setIsCreatePackageOpen] = useState(false);
  const handleCloseCreatePackageDialog = () => {
    setIsCreatePackageOpen(false);
  };

  const handleOpenCreatePackageDialog = () => {
    setIsCreatePackageOpen(true);
  };


  const resetCheckbox =() =>{
    // setAllSelected(false);
    const updatedTimeSlots: AdminTimeSlot[] = adminTimeSlots.map((slot) => {
      return { ...slot, selected: false };
    });
  setAdminTimeSlots(updatedTimeSlots);
  }

  const [dateChanged, setDateChanged] = useState(true);

  const checkCurrentStep = (current_step: string) => {
    switch (current_step) {
      case "booking": {
        let list = returnSelectedTimeSlots();
        console.log(">>>>>>>>>>",list);
        if (list.length > 0 && dateChanged) {
          const adminTimeSlots = list.map(timeSlot => ({
            booked: false,
            selected: true,
            time: timeSlot,
          }));
          setUserBookingInfo(prevUserBookingInfo => ({
            ...prevUserBookingInfo, // Keep the existing values of other properties
            timeslots: adminTimeSlots // Update the services property
          }));
          let date = format(startDate as Date, "MM-dd-yyyy");

          const dateObj = new Date(startDate as Date);
          const month = dateObj.getMonth() + 1; // Adding 1 because months are zero-based.
          const day = dateObj.getDate();
          const year = dateObj.getFullYear();
          setUserBookingInfo(prevUserBookingInfo => ({
            ...prevUserBookingInfo, // Keep the existing values of other properties
            date: month + "-" + day + "-" + year // Update the services property
          }));

          handleOpenDialog();
          return true;
        } else {
          fetchPackages()
          fetchServices()
          handleOpen();
          setToastMessage(currentStep);
          return false;
        }
      }
      case "delete": {
        let list = returnSelectedTimeSlots();
        console.log(list);
        if (list.length > 0 && dateChanged) {
          handleOpenDeleteDialog();
          return true;
        } else {
          handleOpen();
          setToastMessage(currentStep);
          return false;
        }
      }
    }
  };

  const customStyles = {
    content: {
      width: "50%",
      height: "75%",
      margin: "auto",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  const customDeleteStyles = {
    content: {
      width: "25%",
      height: "15%",
      margin: "auto",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  const customCreatePackageStyles = {
    content: {
      width: "50%",
      height: "45%",
      margin: "auto",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "white",
      zIndex: 999,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const [allSelected, setAllSelected] = useState(true);
  const [activeView, setActiveView] = useState("booking");

  const [services, setServices] = useState([]);
  const [news, setNews] = useState([]);
  const [presentations, setPresentations] = useState([]);

  // Function to fetch services
  const fetchServices = async () => {
    try {
      const response = await fetch("https://numbers.nikolakolevski.com/api/services");
      const data = await response.json();
      setServices(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  const fetchNews = async () => {
    try {
      const response = await fetch("https://numbers.nikolakolevski.com/api/news");
      const data = await response.json();
      setNews(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };
  const fetchPresentation = async () => {
    try {
      const response = await fetch("https://numbers.nikolakolevski.com/api/presentation");
      const data = await response.json();
      setPresentations(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const [packages, setPackages] = useState<PackageItem[]>([]);
  const [youtube, setYoutube] = useState<string[]>([]);

  function addYoutubeLink(link: string) {
    setYoutube((prevLinks) => [...prevLinks, link]);
  }

  function removeYoutubeLink(index: number) {
    setYoutube((prevLinks) => prevLinks.filter((_, i) => i !== index));
  }

  const updateYoutubeLink = (newValue:string, index:any) => {
    const updatedLinks = [...youtube];
    updatedLinks[index] = newValue;
    setYoutube(updatedLinks);
  };

  // Function to fetch services
  const fetchPackages = async () => {
    try {
      const response = await fetch("https://numbers.nikolakolevski.com/api/packages");
      const data = await response.json();

      setPackages(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  // Function to fetch services
  const fetchYoutube = async () => {
    try {
      const response = await fetch("https://numbers.nikolakolevski.com/api/youtube");
      const data = await response.json();
      setYoutube(data[0]); // Update the services state with fetched data

    } catch (error) {

      console.error("Error fetching services:", error);
    }
  };

  const approveSelectedTimeslots =async () => {
    let bo = returnSelectedBooking()
    if (bo?.booking_id?.length != undefined  && bo?.booking_id?.length > 0 && bo?.user_email.length > 0) {
      const approveBookingUrl = `https://numbers.nikolakolevski.com/approve-booking/${bo?.booking_id}/${bo?.user_email}`;
      // Make the API call to update the booking status
      await axios.put(approveBookingUrl)
        .then((response: { data: { message: any; }; }) => {
          console.log(response.data.message); // Booking status updated successfully
          fetchTimeSheet()
          // Handle any further actions on the client side if needed
        })
        .catch((error: any) => {
          console.error('Error updating booking status:', error);
          // Handle the error on the client side
        });
      }
  }

  // Use effect hook to fetch services whenever activeView changes
  useEffect(() => {

    if (activeView === "services") {
    } else if (activeView === "packages") {
    } else if ( activeView === "news") {
      fetchNews();
    } else if (activeView ==="booking") {
      fetchServices();
      fetchPackages();
    } else if (activeView === "presentation") {
      fetchPresentation()
    }
     else if (activeView === "youtube") {
      fetchYoutube()
    }
  }, [activeView]);

  const handleViewChange = (view: string) => {
    setActiveView(view);
  };
  const saveYoutubeVideos  = async () => {
    try {
      const response = await fetch('https://numbers.nikolakolevski.com/api/update-videos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(youtube),
      });

      if (response.ok) {
        console.log('Videos updated successfully');
        // Handle any further actions after a successful update
      } else {
        console.error('Failed to update videos');
        // Handle error cases
      }
    } catch (error) {
      console.error('Error updating videos:', error);
      // Handle error cases
    }
  };

  const handleValueChange = (event:any) => {
    const { name, value } = event.target;

    setUserBookingInfo(prevUserBookingInfo => ({
      ...prevUserBookingInfo,
      user: {
        ...prevUserBookingInfo.user,
        [name]: value
      }
    }));
  };

  return (
    <div className="container minh-100">
      <div style={{ minHeight: "3rem", display: "flex", width: "100%" }}>
        <div
          style={{
            justifyContent: "start",
            borderBottom: "1px solid black",
            marginBottom: "2rem",
            paddingBottom: "1rem",
            width: "100%",
          }}
        >
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "booking" ? "#DA327D" : "",
              color: activeView === "booking" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("booking")}
            startIcon={<CalendarMonthIcon />}
            variant="outlined"
          >
            {getTranslation("buttons.booking")}
          </Button>
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "packages" ? "#DA327D" : "",
              color: activeView === "packages" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("packages")}
            startIcon={<Inventory2Icon />}
            variant="outlined"
          >
            {getTranslation("buttons.packages")}
          </Button>
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "services" ? "#DA327D" : "",
              color: activeView === "services" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("services")}
            startIcon={<LightbulbIcon />}
            variant="outlined"
          >
            {getTranslation("buttons.services")}
          </Button>
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "news" ? "#DA327D" : "",
              color: activeView === "news" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("news")}
            startIcon={<ArticleIcon />}
            variant="outlined"
          >
            {getTranslation("buttons.news")}
          </Button>
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "presentation" ? "#DA327D" : "",
              color: activeView === "presentation" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("presentation")}
            startIcon={<ArticleIcon />}
            variant="outlined"
          >
            {getTranslation("buttons.presentations")}
          </Button>
          <Button
            style={{
              margin: "0.5rem",
              background: activeView === "youtube" ? "#DA327D" : "",
              color: activeView === "youtube" ? "white" : "black",
              border: "1px solid  black",
            }}
            onClick={() => handleViewChange("youtube")}
            startIcon={<ArticleIcon />}
            variant="outlined"
          >
            {getTranslation("buttons.youtube")}
          </Button>
        </div>
        {/* Booking Buttons */}
        {activeView === "booking" && (
          <div
            style={{
              display: !(currentStep === "booking") ? "none" : "flex",
              justifyContent: "end",
              borderBottom: "1px solid black",
              marginBottom: "2rem",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <Button
              style={{
                margin: "0.5rem",
                background: !allSelected ? "#DA327D" : "",
                color: !allSelected ? "white" : "black",
                border: "1px solid  black",
              }}
              startIcon={<SaveOutlined />}
              onClick={(e: any) => {
                approveSelectedTimeslots()
              }}
              variant="outlined"
            >
              {getTranslation("buttons.approve")}
            </Button>
            {/* <Button
              style={{
                margin: "0.5rem",
                background: !allSelected ? "#DA327D" : "",
                color: !allSelected ? "white" : "black",
                border: "1px solid  black",
              }}
              startIcon={<DoneAllIcon />}
              onClick={(e: any) => {
                if (dateChanged) {
                  setAllSelected(!allSelected);
                  const updatedTimeSlots: AdminTimeSlot[] = adminTimeSlots.map((slot) => {
                    return { ...slot, selected: allSelected };
                  });
                setAdminTimeSlots(updatedTimeSlots);
                }
              }}
              variant="outlined"
            >
              {getTranslation("buttons.select_all")}
            </Button> */}
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<SaveIcon />}
              onClick={(e: any) => {
                checkCurrentStep("booking");
              }}
              variant="outlined"
            >
              {getTranslation("buttons.book_timeslot")}
            </Button>
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<DeleteIcon />}
              onClick={(e) => {
                // setContactForm(true)
                checkCurrentStep("delete");
              }}
              variant="outlined"
            >
              {getTranslation("buttons.clear_timeslot")}
            </Button>
          </div>
        )}
        {activeView === "packages" && (
          <div
            style={{
              display: !(currentStep === "booking") ? "none" : "flex",
              justifyContent: "end",
              borderBottom: "1px solid black",
              marginBottom: "2rem",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<AddCircleIcon />}
              onClick={(e: any) => {
                // @ts-ignore
                openCreatePackageView.current();
              }}
              variant="outlined"
            >
              {getTranslation("buttons.create_package")}
            </Button>
          </div>
        )}
        {activeView === "youtube" && (
          <div
            style={{
              display: !(currentStep === "booking") ? "none" : "flex",
              justifyContent: "end",
              borderBottom: "1px solid black",
              marginBottom: "2rem",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<AddCircleIcon />}
              onClick={(e: any) => {
                // @ts-ignore
                addYoutubeLink("")
              }}
              variant="outlined"
            >
              {getTranslation("buttons.add_link")}
            </Button>
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<AddCircleIcon />}
              onClick={(e: any) => {
                // @ts-ignore
                saveYoutubeVideos()
              }}
              variant="outlined"
            >
              {getTranslation("buttons.save_changes")}
            </Button>
          </div>
        )}
        {activeView === "services" && (
          <div
            style={{
              display: !(currentStep === "booking") ? "none" : "flex",
              justifyContent: "end",
              borderBottom: "1px solid black",
              marginBottom: "2rem",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<AddCircleIcon />}
              onClick={(e: any) => {
                // @ts-ignore
                openCreateView.current();
              }}
              variant="outlined"
            >
              {getTranslation("buttons.create_service")}
            </Button>
          </div>
        )}
        {activeView === "news" && (
          <div
            style={{
              display: !(currentStep === "booking") ? "none" : "flex",
              justifyContent: "end",
              borderBottom: "1px solid black",
              marginBottom: "2rem",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <Button
              style={{
                margin: "0.5rem",
                color: "black",
                border: "1px solid  black",
              }}
              startIcon={<AddCircleIcon />}
              onClick={(e: any) => {
                handleOpenCreateNews();
              }}
              variant="outlined"
            >
              {getTranslation("buttons.create_news")}
            </Button>
          </div>
        )}
      </div>

      {activeView === "booking" && (
        <div>
          {/* CALENDAR */}
          <div className="calendar_display">
            <div className="calendar" hidden={!(currentStep === "booking")}>
              <Calendar
                selectRange={false}
                defaultValue={startDate}
                onChange={(e: any) => {
                  setStartDate(e as Date);
                  setDateChanged(false);
                  setAllSelected(true);
                }}
              />
              <div className="check_button">
                <Button
                  onClick={(e: any) => {
                    fetchTimeSheet();
                  }}
                  variant="outlined"
                >
                  {getTranslation("buttons.check_period")}
                </Button>
              </div>
            </div>

            {/* TIMESLOTS */}

            <div className="reservation">
              <div hidden={!(currentStep === "booking" && dateChanged)}>
                {adminTimeSlots.length > 0 ? (
                  adminTimeSlots.map((timeSlot: AdminTimeSlot, index:number) => {
                    let same_booking = false
                    if (index < adminTimeSlots.length-1 ) {
                      let next_timeslot = adminTimeSlots[index +1]
                      console.log(timeSlot.time, " --------", next_timeslot.time)
                      if ( next_timeslot.booked && next_timeslot.booking_info.booking_id != null) {
                        same_booking= true
                      }
                    }
                    return (
                      // <div key={timeSlot.time} className={"timeslot"}>
                       <div key={timeSlot.time} className={"admin_timeslot"}>
                        <input
                          type="checkbox"
                          checked={timeSlot.selected}
                          className="checkbox_field"
                          // style={{padding:'0.5rem', margin:'1rem', }}
                          // checked={selectedValues.includes('Option 1')}
                          onChange={(e: any) => {
                            setClickedTime(timeSlot);
                          }}
                        />
                        {!timeSlot.booked ? (
                        <div className={"regular"} >
                            <div style={{padding:'0.2rem 0.5rem', border:'1px solid gray', borderRadius:'50px', fontSize:'15px'}}> {timeSlot.time}</div>
                            <div>Слободно</div>
                            <div> <ModeEdit sx={{color:"gray"}}/> <DeleteIcon  sx={{color:"gray"}} /></div>
                          </div>
                        ) : (
                          // <div className={index === 1  ||index === 0 ? "same_order" : "regular"} >
                          <div className={true ? "regular" :"same_order"} >
                          <div style={{display:'flex'}}>
                            <div style={{padding:'0.2rem 0.5rem',marginRight:'0.5rem', border:'1px solid #DA327D', background:'#DA327D', color:'white',  borderRadius:'50px', fontSize:'15px'}}> {timeSlot.time}</div>
                            <div style={{padding:'0.2rem 0.5rem', border:timeSlot.booking_info.status === 0 ? "1px solid gray":'1px solid black', background:timeSlot.booking_info.status === 0 ? "transparent":"lightgreen", color:timeSlot.booking_info.status === 0 ? "gray":"black",  borderRadius:'50px', fontSize:'15px'}}> {timeSlot.booking_info.status === 0 ? "pending":"approved"}</div>
                          </div>
                          {/* <div>BOOKED</div> */}
                          {/* <div> Package</div> */}
                          {false ? <></>:<>
                          <div>{timeSlot.booking_info.user_email}</div></>}
                          <div> <ModeEdit sx={{color:"gray"}}/> <DeleteIcon  sx={{color:"gray"}} /></div>
                        </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" variant="filled">
              {getTranslation("toast." + toast_message)}
            </Alert>
          </Snackbar>
          {/* DELETE */}
          <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseDialog}
            contentLabel="Dialog"
            // style={{width:'10rem', height:'10rem'}}
            style={customStyles}
          >
            <h2>Book timeslots</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span style={{ paddingLeft: "0.5rem", marginRight: "0.5rem" }}>
                TimeSlots :{" "}
              </span>
              {userBookingInfo.timeslots.length > 0 ? (
                userBookingInfo.timeslots.map((timeSlot: any) => {
                  return  (
                    <>
                      <div
                        key={timeSlot.time}
                        style={{
                          border: "1px solid gray",
                          padding: "0.3rem",
                          margin: "0.3rem",
                          borderRadius: "5px",
                        }}
                      >
                        {timeSlot.time}
                      </div>
                    </>
                  )
                })
              ) : (
                <></>
              )}
            </div>
            <div className="admin_form_container">
              <div style={{display:'block', width:'100%'}}>
              <h1 style={{color:'#00B0B2'}}> {getTranslation('packages')}</h1>
                <div style={{maxHeight:'25vh', overflowY:"auto"}}>
                {packages.map((current_package:PackageItem, index:number)=>{
                  let selected = userBookingInfo?.packages?.includes(current_package.uuid);
                       return (
                        <>
                        <div   onClick={()=>{
                                  if ( selected) {
                                    removePackage(current_package.uuid)
                                  } else {
                                    addPackage(current_package.uuid)

                                  }
                                  // console.log(state.bookingInfo?.packages)
                                }}
                        style={{userSelect:'none', padding:'1rem', margin:'1rem 0rem', borderRadius:'50px',background:selected ? '#DA327D' :'white', color:selected ? 'white' :'black', display:'block', alignItems:'center', border:selected? '1px solid #DA327D' :'1px solid black',}}>
                            <div style={{display:'flex',alignItems:'center',}}>
                              <input

                              type="checkbox"
                              checked = {selected}
                              className='checkbox_field'/>
                              <div style={{marginRight:'1rem', width:'100%'}}>
                                {current_package.mk_name}
                              </div>
                              </div>
                          </div></>
                        )
              })}
                </div>
              <h1 style={{color:'#00B0B2'}}> {getTranslation('services.header')}</h1>
              <div style={{maxHeight:'25vh', overflowY:"auto"}}>
                {services.map((current_package:ServiceItem, index:number)=>{
                 let selected = userBookingInfo?.services?.includes(current_package.uuid);
                       return (
                        <>
                        <div  onClick={()=>{
                                  if ( selected) {
                                    removeService(current_package.uuid)
                                  } else {
                                    addService(current_package.uuid)

                                  }
                                  // console.log(state.bookingInfo?.packages)
                                }}
                        style={{userSelect:'none', padding:'1rem', margin:'1rem 0rem', borderRadius:'50px',background:selected ? '#DA327D' :'white', color:selected ? 'white' :'black', display:'block', alignItems:'center', border:selected? '1px solid #DA327D' :'1px solid black',}}>
                            <div style={{display:'flex',alignItems:'center',}}>
                              <input

                              type="checkbox"
                              checked = {selected}
                              className='checkbox_field'/>
                              <div style={{marginRight:'1rem', width:'100%'}}>
                                {current_package.mk_name}
                              </div>
                              </div>
                          </div></>
                        )
              })}
                </div>
              </div>
              <div className="textfield_form">
                <div className="textfield_container">
                  <TextField
                    name="name"
                    className="textfield"
                    required
                    value={userBookingInfo.user.name}
                    id="outlined-required"
                    placeholder={getTranslation("labels.name")}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="textfield_container">
                  <TextField
                    name="surname"
                    className="textfield"
                    required
                    value={userBookingInfo.user.surname}
                    id="outlined-required"
                    placeholder={getTranslation("labels.surname")}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="textfield_container">
                  <TextField
                    name="email"
                    className="textfield"
                    required
                    value={userBookingInfo.user.email}
                    id="outlined-required"
                    placeholder={getTranslation("labels.email")}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="textfield_container">
                  <TextField
                    name="phone"
                    className="textfield"
                    required
                    value={userBookingInfo.user.phone}
                    id="outlined-required"
                    placeholder={getTranslation("labels.phone")}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="textfield_container">
                  <TextField
                    name="subject"
                    className="textfield"
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    placeholder={getTranslation("labels.subject")}
                    onChange={handleValueChange}
                  />
                </div>
            <div style={{ minHeight: "3rem", width: "100%" }}>
              <div
                style={{
                  display: !(currentStep === "booking") ? "none" : "flex",
                  justifyContent: "end",
                  marginBottom: "2rem",
                  paddingBottom: "1rem",
                }}
              >
                <Button
                  style={{
                    margin: "0.5rem 0rem 0.5rem 0.5rem",
                    color: "black",
                    border: "1px solid  black",
                  }}
                  startIcon={<CloseIcon />}
                  onClick={(e: any) => {
                    handleCloseDialog();
                    resetUserBookingInfo()
                  }}
                  variant="outlined"
                >
                  {getTranslation("buttons.close")}
                </Button>
                <Button
                  style={{
                    margin: "0.5rem",
                    color: "black",
                    border: "1px solid  black",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={(e: any) => {
                    // 
                    // checkCurrentStep("booking");
                    sendBookingForm()
                    console.log(userBookingInfo);
                  }}
                  variant="outlined"
                >
                  {getTranslation("buttons.book_timeslot")}
                </Button>
              </div>
            </div>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isDeleteOpen}
            onRequestClose={handleCloseDeleteDialog}
            contentLabel="Dialog"
            // style={{width:'10rem', height:'10rem'}}
            style={customDeleteStyles}
          >
            <h2>Are you sure you want to delete ?</h2>
            <div style={{ minHeight: "3rem", width: "100%" }}>
              <div
                style={{
                  display: !(currentStep === "booking") ? "none" : "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    margin: "0.5rem 0rem 0.5rem 0.5rem",
                    color: "black",
                    border: "1px solid  black",
                  }}
                  startIcon={<CloseIcon />}
                  onClick={(e: any) => {
                    handleCloseDeleteDialog();
                  }}
                  variant="outlined"
                >
                  {getTranslation("buttons.close")}
                </Button>
                <Button
                  style={{
                    margin: "0.5rem",
                    color: "black",
                    border: "1px solid  black",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={(e: any) => {}}
                  variant="outlined"
                >
                  {getTranslation("buttons.yes")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {/* -------------------------------------------------------- */}
      {activeView === "packages" && (
        <PackagesComponent
          packages={packages}
          openCreateView={openCreatePackageView}
          refreshPackages={fetchPackages}
          handleCloseCreatepackages={handleCloseCreateServices}
        />
      )}
      {activeView === "services" && (
        <ServicesComponent
          services={services}
          openCreateView={openCreateView}
          refreshServices={fetchServices}
          handleCloseCreateServices={handleCloseCreateServices}
        />
      )}
      {activeView === "news" && (
        <NewsComponent
          news= {news}
          refreshNews={fetchNews}
          isSetCreateNews={isSetCreateNews}
          handleCloseCreateNews={handleCloseCreateNews}
        />
      )}
      {activeView === "presentation" && <>
      <div className="news" style={{marginBottom:'5rem'}}>
        <div className="news_header">
          <div className="table-heading">{getTranslation("booking_time")}</div>
          <div className="table-heading">{getTranslation("labels.name")}</div>
          <div className="table-heading">{getTranslation("labels.surname")}</div>
          <div className="table-heading">{getTranslation("labels.email")}</div>
          <div className="table-heading">{getTranslation("labels.phone")}</div>
          {/* <div className="table-heading">{getTranslation("actions")}</div> */}
        </div>
        {presentations?.map((current_news:any, index:number) => {
          function setCurrentNewsIndex(index: number) {
            // setIsEditView(true)
            // setCurrentIndex(index)
          }

          if(current_news.name.length === 0 )
           {
            return
           }

          return (
            <div className="news_not_selected" key={current_news.name}>
              <div>{current_news.date}</div>
              <div>{current_news.name}</div>
              <div>{current_news.surname}</div>
              <div>{current_news.email}</div>
              <div>{current_news.phone}</div>

            </div>
          );
        })}
      </div>


      </>}
      {activeView === "youtube" && <>
      <div id="youtube_videos">
        <h2>{getTranslation("youtube_admin")}</h2>
        <div id="youtube_videos_container" className="admin_news_content">
          {youtube.map((link:string, index) => (
            <div className="youtube_video" style={{maxWidth:'100%', minWidth:'100%', display:'block', padding:'2rem 0.3rem 0rem 0.3rem',}} key={index}>
              <div style={{display:'flex', flexDirection:'row', alignContent:'center', alignItems:'center'}}>                 <TextField
                    name="name"
                    className="textfield"
                    required
                    style={{marginBottom:'1rem'}}
                    value={link}
                    id="outlined-required"
                    label={getTranslation("youtube_link")}
                    placeholder={getTranslation("labels.name")}
                    onChange={(e) => updateYoutubeLink(e.target.value, index)}
                  />
                  <DeleteIcon  onClick={(e) => {
                // setContactForm(true)
                removeYoutubeLink(index)
              }} sx={{marginLeft:'0.5rem'}} /></div>

              <YouTube opts={playerOpts} videoId={link} />
            </div>
          ))}
        </div>
      </div></>}
    </div>
  );
};

export default AdminWelcome;
